import { Column } from '@/components/onx/table';
import { ascNumberSort } from '@/utils/sorting';

type CongestionCommonStartColumns = {
  operatorName: string;
  enodebid: number;
  cellId: number;
  connectionCategory: string;
};

type CongestionCommonEndColumns = {
  downloadThroughput: number | null;
  uploadThroughput: number | null;
  signalStrength: number | null;
  packetLossAndDiscard: number | null;
};

/**
 * These columns are common to the start of all three congestion tables.
 */
export const congestionCommonStartColumns: Column<CongestionCommonStartColumns>[] = [
  {
    value: (row) => row.operatorName,
    header: 'Operator',
    key: 'operator',
    filterable: true,
  },
  {
    value: (row) => row.enodebid,
    header: 'eNodeB ID',
    key: 'connection_enodebid',
  },
  {
    value: (row) => row.cellId,
    header: 'Cell ID',
    key: 'connection_cid',
  },
  {
    value: (row) => row.connectionCategory,
    header: 'Connection Category',
    key: 'connection_category',
  },
];

/**
 * These four columns use the same values and print the same data on all three of these
 * tables, at the very end. Signal Strength should be rounded, but the others get a couple
 * decimal places.
 */
export const congestionCommonEndColumns: Column<CongestionCommonEndColumns>[] = [
  {
    value: (row) => row.downloadThroughput,
    cell: (row) => (row.downloadThroughput != null ? row.downloadThroughput.toFixed(2) : ''),
    header: 'Download Throughput (Mbps)',
    key: 'download_throughput',
    sort: ascNumberSort,
  },
  {
    value: (row) => row.uploadThroughput,
    cell: (row) => (row.uploadThroughput != null ? row.uploadThroughput.toFixed(2) : ''),
    header: 'Upload Throughput (Mbps)',
    key: 'upload_throughput',
    sort: ascNumberSort,
  },
  {
    value: (row) => row.signalStrength,
    cell: (row) => (row.signalStrength != null ? Math.round(row.signalStrength) : ''),
    header: 'Signal Strength (dBm)',
    key: 'signal_strength',
    sort: ascNumberSort,
  },
  {
    value: (row) => row.packetLossAndDiscard,
    cell: (row) => (row.packetLossAndDiscard != null ? row.packetLossAndDiscard.toFixed(2) : ''),
    header: 'Packet Loss & Discard (%)',
    key: 'packet_loss_and_discard',
    sort: ascNumberSort,
  },
];
