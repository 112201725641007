<template>
  <tileTooltip :content="'Export this view as image'">
    <template #tooltipAnchor>
      <div class="HtmlImageExport" data-html2canvas-ignore>
        <a ref="print" :download="`${title}.png`" @click="print">
          <DownloadFileIcon button />
        </a>
      </div>
    </template>
  </tileTooltip>
</template>

<script>
import html2canvas from 'html2canvas';
import TileTooltip from '@/components/comparison/TileTooltip';
import spotlightLogo from '@/assets/onx/logo/onxspotlight.svg';
import DownloadFileIcon from '@/components/onx/icons/DownloadFileIcon';
import colors from '@/utils/colorPalette';

export default {
  name: 'HtmlImageExport',
  components: {
    DownloadFileIcon,
    TileTooltip,
  },
  props: {
    reference: {
      type: HTMLElement,
      default: undefined,
    },
    getElement: {
      type: Function,
      default: undefined,
    },
    title: {
      type: String,
      default: 'title',
    },
  },
  data() {
    return {
      debug: false,
      icons: {
        spotlight: spotlightLogo,
      },
      // window.devicePixelRation enforced to 2 for retina like
      // rendering on any screen
      dpi: 2,
      footer: 100,
      isLoading: false,
    };
  },
  computed: {
    exportTarget() {
      return this.getElement?.() || this.reference;
    },
  },
  methods: {
    async print() {
      const container = document.createElement('div');

      /**
       * This temporary container element is used to place the content to export
       * into the limits we want the exported image to have (3 columns)
       *
       * 3 columns = ~1500px
       *
       * And because html2canvas needs a mounted, visible element to work,
       * this element is placed well outside of the viewport, and with position: fixed so it doesn't affect the layout
       * It is removed when the export is done (or fails).
       */
      container.style.width = '1500px';

      container.style.position = 'fixed';
      container.style.top = '-10000px';

      container.appendChild(this.exportTarget.cloneNode(true));
      document.body.appendChild(container);

      this.isLoading = true;
      if (!this.$refs.print.href) {
        const picture = document.createElement('canvas');
        picture.width = container.clientWidth * this.dpi;
        picture.height = (container.clientHeight + this.footer) * this.dpi;

        const ctx = picture.getContext('2d');

        await this.drawBackgroundAndWatermark(ctx, container.clientWidth, container.clientHeight);

        const screenShot = new Image();
        screenShot.onload = () => {
          ctx.drawImage(screenShot, 0, 0, container.clientWidth * this.dpi, container.clientHeight * this.dpi);

          if (this.debug) {
            this.exportOnScreen(picture);
          } else {
            this.$refs.print.href = picture.toDataURL();
            this.$refs.print.click();
            this.$refs.print.removeAttribute('href');
          }

          document.body.removeChild(container);
        };

        screenShot.src = await this.captureScreen(container);
      }

      this.isLoading = false;
    },
    async drawBackgroundAndWatermark(ctx, width, height) {
      ctx.fillStyle = '#DBDFEA';
      ctx.fillRect(0, 0, width * this.dpi, (height + this.footer) * this.dpi);

      const img = new Image();
      const imgLeft = 20 * this.dpi;
      const footerTop = (height + 10) * this.dpi;
      await new Promise((resolve) => {
        img.onload = () => {
          ctx.drawImage(img, imgLeft, footerTop, img.naturalWidth * this.dpi, img.naturalHeight * this.dpi);
          img.remove();
          resolve();
        };
        img.src = this.icons.spotlight;
      });

      ctx.font = 'bold 64px BeVietnamPro';
      ctx.fillStyle = colors.colorBlueHeader;
      ctx.textAlign = 'hanging';
      ctx.fillText(
        '© Copyright ' + (new Date().getYear() + 1900) + ' Opensignal',
        ctx.canvas.width - 500 * this.dpi,
        ctx.canvas.height - 32 * this.dpi,
      );
    },
    async captureScreen(container) {
      const data = await html2canvas(container, {
        backgroundColor: 'transparent',
        scale: this.dpi,
      });

      return data.toDataURL();
    },
    exportOnScreen(canvas) {
      canvas.style.position = 'fixed';
      canvas.style.top = '100px';
      canvas.style.left = '20px';
      canvas.style.zIndex = '100';
      canvas.style.boxShadow = '0 0 4px #000';
      const c = document.querySelector('body > canvas');
      if (c) {
        c.parentElement.removeChild(c);
      }
      document.body.appendChild(canvas);
    },
  },
};
</script>
<style lang="scss">
@use 'scss/variables.module' as *;
@import 'scss/components';

.HtmlImageExport {
  height: 24px;
  width: 24px;
}
</style>
