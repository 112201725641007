import { computed, watchEffect } from 'vue';

import type { Dashboards } from '@/constants/dashboards';
import useFilters from '@/composables/useFilters';
import useAvailableConnectionCategories from '@/focus/composables/useAvailableConnectionCategories';
import usePossibleConnectionCategories from '@/connection-categories-provider/usePossibleConnectionCategories';
import useAnalytics from '@/composables/useAnalytics';

export const useMapConnectionCategory = (dashboard: Dashboards) => {
  const { mapConnectionCategory, setFilters } = useFilters(dashboard);
  const availableConnectionCategories = useAvailableConnectionCategories();
  const allPossibleConnectionCategories = usePossibleConnectionCategories();
  const { track } = useAnalytics();

  const mapConnectionCategories = computed(() => {
    return allPossibleConnectionCategories.map((category) => ({
      ...category,
      disabled: !availableConnectionCategories.value.includes(category.categoryValue),
    }));
  });

  const selectedMapConnectionCategory = computed({
    get: () => {
      return mapConnectionCategories.value.find((category) => category.categoryValue === mapConnectionCategory.value);
    },
    set: (connectionCategory: any) => {
      setFilters({ mapConnectionCategory: connectionCategory.categoryValue });
    },
  });

  const onMapConnectionCategoryChange = (connectionCategory: any) => {
    selectedMapConnectionCategory.value = connectionCategory;
    track('map connection category change', {
      connectionCategory,
    });
  };

  watchEffect(() => {
    if (availableConnectionCategories.value.length === 0) {
      return;
    }

    const selectedCategory = selectedMapConnectionCategory.value;
    if (selectedCategory?.disabled) {
      selectedMapConnectionCategory.value = mapConnectionCategories.value.find((category) => !category.disabled);
    }
  });

  return {
    selectedMapConnectionCategory,
    onMapConnectionCategoryChange,
    mapConnectionCategories,
  };
};
