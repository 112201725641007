<script setup lang="ts">
import { computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import axios from 'axios';

type Props = {
  iso3: string;
};

const props = defineProps<Props>();

/**
 * Retrieve the SVG flag asynchronously
 *
 * This component was created to solve an issue with html2canvas not rendering SVG URLs
 * - following the recommended way to use the library, adding .fi.fi-{iso2} as classes to an element will set background-image: url('path-to-svg').
 *    This will fail on export to canvas because the SVGs themselves do not have width and height attributes, and html2canvas has trouble with that.
 * - another attempt was using this path as img src, but that failed because the size of the image was not respected in the exported html2canvas image.
 *
 * Finally, OnxFlagIcon solves the issue inlining the SVG and ensuring width and height are set.
 */
const { data } = useQuery({
  queryKey: ['flag', props.iso3],
  queryFn: () => {
    return axios.get<string>(`/flag-icons/4x3/${props.iso3}.svg`);
  },
  staleTime: Infinity,
});

const svg = computed(() => {
  if (!data.value) {
    return;
  }

  const container = document.createElement('div');
  container.innerHTML = data.value.data;

  return container.querySelector('svg');
});

const viewBox = computed(() => {
  if (!svg.value) {
    return '0 0 24 18';
  }

  const viewBoxBaseVal = svg.value.viewBox.baseVal;
  return `${viewBoxBaseVal.x} ${viewBoxBaseVal.y} ${viewBoxBaseVal.width} ${viewBoxBaseVal.height}`;
});

const flagSvgString = computed(() => {
  if (!svg.value) {
    return '';
  }

  return svg.value.innerHTML;
});
</script>

<template>
  <svg class="onx-flag-icon" width="24" height="18" :viewBox="viewBox" v-html="flagSvgString"></svg>
</template>

<style lang="scss" scoped>
.onx-flag-icon {
  border: 1px solid var(--charcoal-100);
  border-radius: 2px;
  box-sizing: content-box;
}
</style>
