<script setup lang="ts">
import { computed, ref } from 'vue';
import { format } from 'date-fns';
import mapboxgl from 'mapbox-gl';

import type { Feature } from 'geojson';

import MultiCountryTrendChart from './MultiCountryTrendChart.vue';
import { useGeoJsonWithColors } from '@/intl-spotlight/by-geography/useGeoJsonWithColors';

import { MetricStructuresEnum } from '@/types/MetricStructures';

import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';
import OnxMapboxBase from '@/components/visual/map/OnxMapboxBase.vue';
import useMapPolygonHoverEffect from '@/components/visual/map/useMapPolygonHoverEffect';
import useMapPolygonLayer from '@/components/visual/map/useMapPolygonLayer';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';
import useEndDate from '@/composables/useEndDate';
import usePolygonsWithMetrics from '@/composables/usePolygonsWithMetrics';
import {
  OS_GEOCODINGS,
  PolygonFillsLayerName,
  PolygonSourceName,
  HARD_FIRST_DATE_AVAILABLE,
} from '@/constants/constants';
import { API_DEFAULT_DATE_FORMAT } from '@/constants/dateFormats';
import { refValueIsPresent } from '@/types/helpers/refValueIsPresent';

import { MapEvents } from '@/components/visual/map/MapEvents';
import createMarker from '@/intl-spotlight/createMarker';
import createMetricKey from '@/utils/createMetricKey';

import IntlSpotlightGlobalStats from '@/intl-spotlight/by-geography/IntlSpotlightGlobalStats.vue';
import NationalStatsChart from '@/intl-spotlight/by-geography/NationalStatsChart.vue';

import LoaderGrid from '@/components/LoaderGrid.vue';

const dashboard = useCurrentDashboardName();

const { currentEndDate } = useEndDate(dashboard.value, new Date(HARD_FIRST_DATE_AVAILABLE));
const { agg, connectionCategory, metric, userGroup } = useSpotlightQueryParams({
  agg: '90days',
});

const metricKey = computed(() => {
  return createMetricKey(metric.toValue(), userGroup.toValue(), connectionCategory.toValue());
});

const { polygonQueriesLoading, polygonsWithMetrics } = usePolygonsWithMetrics<MetricStructuresEnum.RankedSimple>(
  dashboard.value,
  {
    metric: metricKey,
    geocoding: OS_GEOCODINGS.countries,
    aggregation: agg.selectedValue,
    endDate: currentEndDate,
    operatorInfo: true,
  },
);

const { geoJsonWithColors } = useGeoJsonWithColors(polygonsWithMetrics);

const hoveredFeature = ref<Feature>();

const onFeatureHover = async (feature: Feature) => {
  hoveredFeature.value = feature;
};

const onFeatureLeave = () => {
  hoveredFeature.value = undefined;
};

const { setupPolygonLayer } = useMapPolygonLayer();
const { setupHoverEffect } = useMapPolygonHoverEffect(PolygonSourceName, PolygonFillsLayerName, {
  onEnter: onFeatureHover,
  onLeave: onFeatureLeave,
});

const onMapReady = (map: mapboxgl.Map) => {
  if (!refValueIsPresent(geoJsonWithColors)) {
    return;
  }

  setupPolygonLayer(map, geoJsonWithColors);
  setupHoverEffect(map);

  if (geoJsonWithColors.value) {
    geoJsonWithColors.value.features.forEach((feature) => {
      const rank = (feature?.properties as any).homeNetworkData?.rank;
      const marker = createMarker(rank);

      new mapboxgl.Marker(marker).setLngLat(feature.center).addTo(map);
    });
  }
};
</script>

<template>
  <div class="intl-spotlight-by-geography">
    <div class="intl-spotlight-by-geography__map-container standard-mb">
      <template v-if="!polygonQueriesLoading">
        <OnxMapboxBase
          v-if="geoJsonWithColors"
          :geo-json="geoJsonWithColors"
          class="intl-spotlight-by-geography__map"
          @[MapEvents.MapReady]="onMapReady"
        />
      </template>
      <LoaderGrid v-else />

      <NationalStatsChart
        v-if="hoveredFeature"
        :metric-key="metricKey"
        :feature="hoveredFeature"
        :end-date="currentEndDate"
      />

      <IntlSpotlightGlobalStats
        v-else
        class="intl-spotlight-by-geography__global-stats"
        :aggregation="agg.selectedValue.value"
        :dashboard="dashboard"
        :metric="metricKey"
        :endDate="format(currentEndDate, API_DEFAULT_DATE_FORMAT)"
      />
    </div>
    <MultiCountryTrendChart
      :aggregation="agg.selectedValue.value"
      :endDate="currentEndDate"
      :metric="metricKey"
      :user-group="userGroup.selectedValue.value"
    />
  </div>
</template>

<style lang="scss">
@use 'scss/onx-breakpoints.module' as breakpoints;

.intl-spotlight-by-geography {
  &__map-container {
    position: relative;
    height: 560px;
  }

  &__map {
    height: 560px;
  }

  &__map-marker {
    width: 32px;
    height: 32px;

    &__text {
      color: white;

      position: absolute;
      top: 4px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__global-stats,
  &__national-stats {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 1;

    min-width: 100px;
    min-height: 100px;

    @include breakpoints.tablet {
      top: 16px;
      left: 16px;
    }
  }

  .ChartWrapperExtended {
    padding: 16px 24px;
    margin: 8px;

    @include breakpoints.tablet {
      margin: 16px;
    }
  }
}
</style>
