<script>
export default {
  props: {
    fullscreen: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: true,
    },
    overlay: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <div
    class="loader-indicator-base"
    :class="{
      'loader-indicator-base--fullscreen': fullscreen,
      'loader-indicator-base--centered': fullscreen || centered,
    }"
  >
    <div v-if="overlay" class="loader-indicator-base__overlay" />
    <div class="loader-indicator-base__content">
      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss">
.loader-indicator-base--centered {
  position: absolute;
  inset: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-indicator-base--fullscreen {
  position: fixed;
}

.loader-indicator-base__overlay {
  position: absolute;
  inset: 0;
  background-color: var(--charcoal-100);
  opacity: 0.5;
  z-index: 1;
}

.loader-indicator-base__content {
  z-index: 2;
}
</style>
