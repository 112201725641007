<script setup lang="ts">
import { watch, ref, computed, onMounted } from 'vue';
import { onBeforeRouteUpdate, useRouter } from 'vue-router';
import { useActions, useGetters, createNamespacedHelpers } from 'vuex-composition-helpers';

import type { Operator } from '@/types/Operator';
import type { MetricDescriptor } from '@/types/MetricDescriptor';

import CompetitiveTile from '@/components/comparison/CompetitiveTile.vue';
import SpotlightOverviewLayout from '@/components/competitive/SpotlightOverviewLayout.vue';
import AwardIndicator from '@/components/competitive/AwardIndicator.vue';
import HtmlImageExport from '@/components/HtmlImageExport.vue';
import LoaderGrid from '@/components/LoaderGrid.vue';
import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';
import useMetricBoxData from '@/components/onx/composables/useMetricBoxData';
import OnxSpotlightHeaderNav from '@/components/onx/spotlight-header/OnxSpotlightHeaderNav.vue';
import ChevronRightIcon from '@/components/onx/icons/ChevronRightIcon.vue';
import DownloadFileIcon from '@/components/onx/icons/DownloadFileIcon.vue';
import MenuDotsIcon from '@/components/onx/icons/MenuDotsIcon.vue';
import OnxMetricIcon from '@/components/onx/icons/metrics/OnxMetricIcon.vue';
import OnxButton from '@/components/onx/OnxButton.vue';
import { OnxList, OnxListItem, OnxListItemText } from '@/components/onx/OnxList';
import OnxPaper from '@/components/onx/OnxPaper.vue';
import OnxHeadline from '@/components/onx/typography/OnxHeadline.vue';
import OnxSpotlightBreadcrumbs from '@/components/onx/spotlight-header/OnxSpotlightBreadcrumbs.vue';
import ROUTES from '@/constants/routes';
import { isAwardMetric } from '@/utils/metrics';
import { CustomTooltip } from '@/components/tooltip';
import { TOOLTIP_MESSAGES } from '@/constants/tooltips';
import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';
import createMetricKey from '@/utils/createMetricKey';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';
import useEndDate from '@/composables/useEndDate';
import useSpotlightTilesImageExportTitle from '@/spotlight/useSpotlightTilesImageExportTitle';

const router = useRouter();
const matches = useBreakpoints();

const queryParams = useSpotlightQueryParams();

const { setConnectionStats } = useActions(['setConnectionStats']);

const { connectionStats: stats } = useGetters(['connectionStats']);
const { useGetters: useCompetitiveGetters } = createNamespacedHelpers('competitive');
const { useGetters: useChartsGetters } = createNamespacedHelpers('charts');
const { useGetters: useDashboardGetters } = createNamespacedHelpers('dashboard');
const { homeNetwork, selectedNetworkOperators } = useChartsGetters(['selectedNetworkOperators', 'homeNetwork']);
const { getConfidenceState: showConfidenceIntervals } = useDashboardGetters(['getConfidenceState']);

const connections = ['overall', '3glte5g', '5g', '5gmmwave', 'lte5g', 'lte', '3g', '2g', 'wifi', 'nosignal'];

let shouldFetch = true;

const { useGetters: useMetricsGetters } = createNamespacedHelpers('metrics');

const connectionStatsStore = useGetters(['connectionStats/chartPending', 'connectionStats/metric']);

const isPending = computed(() => {
  return connectionStatsStore['connectionStats/chartPending'];
});

const { exportTitle } = useSpotlightTilesImageExportTitle('By Connection');

const parsedUserGroup = computed(() => {
  const userGroupQueryParamValue = queryParams.userGroup.toValue();
  const userGroup = userGroupQueryParamValue === 'main' ? '' : userGroupQueryParamValue;

  return userGroup;
});

const dashboard = useCurrentDashboardName();
const { currentEndDate } = useEndDate(dashboard.value);

const fetchData = () => {
  if (!shouldFetch) {
    shouldFetch = true;
    return;
  }

  setConnectionStats({
    metricString: connections
      .map((cc) => createMetricKey(queryParams.metric.toValue(), parsedUserGroup.value, cc))
      .join(','),
    agg: queryParams.agg.toValue(),
    date: currentEndDate.value.toISOString(),
    location: queryParams.location.toValue(),
  });
};

const { createMetricBoxData } = useMetricBoxData('connectionStats');
const { getOrderedValues } = useCompetitiveGetters(['getOrderedValues']);
const { allMetrics } = useMetricsGetters(['allMetrics']);

const tiles = computed(() => {
  if (!queryParams.metric.toValue() || !allMetrics.value) {
    return [];
  }

  const selectedNetworkOperatorIDs = (selectedNetworkOperators.value || []).map(
    (network: Operator) => network.canonical_network_id,
  );
  const boxes = connections
    .map((connection) => {
      const metricKey = createMetricKey(queryParams.metric.toValue(), parsedUserGroup.value, connection);

      const metricObject = allMetrics.value.find((m: MetricDescriptor) => {
        return m.key === metricKey;
      });

      if (!metricObject) {
        return;
      }

      const ranksSource = getOrderedValues.value(stats.value, metricObject).filter((rank: any) => {
        return selectedNetworkOperatorIDs.includes(rank.canonical_network_id);
      });

      return createMetricBoxData(
        ranksSource,
        {
          ...metricObject,
          isAwardMetric: isAwardMetric(metricObject),
        },
        queryParams.compareTo.toValue(),
      );
    })
    .filter((b): b is ReturnType<typeof createMetricBoxData> => !!b && b.ranks.length);

  return boxes;
});

onMounted(() => {
  fetchData();
});

watch(
  () => router.currentRoute.value.query,
  () => {
    fetchData();
  },
);

onBeforeRouteUpdate((to, from) => {
  shouldFetch = to.query.compareTo === from.query.compareTo;
});

const navigate = (metric: MetricDescriptor) => {
  router.push({
    name: ROUTES.CompetitiveDetails,
    query: {
      ...router.currentRoute.value.query,
      metric: metric.subtype,
      userGroup: metric.subcategory,
      connectionCategory: metric.type,
    },
  });
};

const htmlImageExportRef = ref();

const onHtmlImageExportListItemClick = () => {
  if (!htmlImageExportRef.value) {
    return;
  }

  htmlImageExportRef.value.print();
};
</script>

<template>
  <div class="ConnectionCategory">
    <SpotlightOverviewLayout :number-of-tiles="tiles.length">
      <template #header="headerProps">
        <OnxSpotlightBreadcrumbs />
        <OnxSpotlightHeaderNav>
          <template #actions>
            <HtmlImageExport
              v-show="matches.desktop.value"
              ref="htmlImageExportRef"
              :reference="headerProps.htmlImageExportData.spotlightParent.ref"
              :title="exportTitle"
            />

            <VDropdown
              v-if="matches.mobile.value && !matches.desktop.value"
              :distance="6"
              class="onx-navigation-header__settings"
            >
              <span>
                <MenuDotsIcon button />
              </span>

              <template #popper>
                <OnxPaper :depth="3">
                  <OnxList>
                    <OnxListItem extra-x-padding @click.prevent="onHtmlImageExportListItemClick">
                      <OnxListItemText size="sm"> Save as image </OnxListItemText>
                      <template #right>
                        <DownloadFileIcon />
                      </template>
                    </OnxListItem>
                  </OnxList>
                </OnxPaper>
              </template>
            </VDropdown>
          </template>
        </OnxSpotlightHeaderNav>
      </template>

      <template #competitiveTiles>
        <template v-if="!isPending">
          <LoaderGrid class="loader" />
        </template>

        <template v-else>
          <template v-if="tiles.length">
            <CompetitiveTile
              v-for="tile in tiles"
              :key="tile.metricKind"
              :metric="tile.rawMetric"
              :metric-type="tile.metricType"
              :metric-kind="tile.metricKind"
              :metric-unit="tile.metricUnit"
              :metric-category="tile.metricCategory"
              :bigger-better="tile.biggerBetter"
              :title="tile.title"
              :ranks="tile.ranks"
              :granularity="tile.granularity"
              :is-loading="false"
              @navigate="navigate(tile.metric)"
              :home-network="homeNetwork"
              :show-confidence-intervals="showConfidenceIntervals"
            >
              <template #title>
                <CustomTooltip
                  :message="
                    TOOLTIP_MESSAGES[`spotlight_${tile.metricKind}_${tile.metricType}` as keyof typeof TOOLTIP_MESSAGES]
                  "
                  placement="top"
                >
                  <OnxMetricIcon :metric-kind="tile.metricKind" class="Card__header__metric-icon" />
                </CustomTooltip>

                <div class="Card__headerTitle">
                  <OnxHeadline as="h4">
                    {{ tile.title }}
                    <AwardIndicator v-if="tile.isAwardMetric" :userGroup="tile.metricUserGroup" />
                  </OnxHeadline>
                </div>
              </template>

              <template #actions>
                <OnxButton variant="tertiary" size="sm" @click="navigate(tile.rawMetric)">
                  <span>By geography</span>
                  <ChevronRightIcon small />
                </OnxButton>
              </template>
            </CompetitiveTile>
          </template>
        </template>
      </template>
    </SpotlightOverviewLayout>
  </div>
</template>

<style lang="scss">
.ConnectionCategory {
  .onx-metric-box__header {
    min-height: 170px;
  }

  .onx-headline {
    display: flex;
    align-items: center;
  }
}
</style>
