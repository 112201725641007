<script setup lang="ts">
import { computed, watch, onMounted } from 'vue';

import CoreHealthVideoResolutionCharts from './CoreHealthVideoResolutionCharts.vue';
import type { ChartMetricDefinitionWithSingleSubtype } from '@/chart-metric-definitions/MetricDefinition';

import useChooseDifferentMetricSubtype from '@/focus/core-health/composables/useChooseDifferentMetricSubtype';
import useFilters from '@/composables/useFilters';
import useFocusChartConnectionCategories from '@/focus/composables/useFocusChartConnectionCategories';
import { ChartConnectionCategoriesSelector } from '@/focus/connection-category-selector';
import LoaderGrid from '@/components/LoaderGrid.vue';
import DbScalingGrid from '@/components/DbScalingGrid.vue';
import OnxButton from '@/components/onx/OnxButton.vue';
import OnxPaper from '@/components/onx/OnxPaper.vue';
import OnxSelectorBlock from '@/components/onx/OnxSelectorBlock.vue';
import OnxHeadline from '@/components/onx/typography/OnxHeadline.vue';
import { ChoroplethMap } from '@/components/visual';
import useCoreHealthByCdn from '@/composables/useCoreHealthByCDN';
import useDebouncedRef from '@/composables/useDebouncedRef';
import useGeocoding from '@/composables/useGeocoding';
import useMapWithPolygons from '@/composables/useMapWithPolygons';
import useSelectableNetworkOperators from '@/composables/useSelectableNetworkOperators';
import { Dashboards } from '@/constants/dashboards';
import useGeohashesQueryParam from '@/focus/composables/query-params/useGeohashesQueryParam';
import useLookingAtLocationTitle from '@/focus/composables/useLookingAtLocationTitle';
import SingleMetricSelectorDropdown from '@/focus/metric-selector/SingleMetricSelectorDropdown.vue';
import useChartMetricSingleSelect from '@/focus/metric-selector/useChartMetricSingleSelect';
import { MetricStructuresEnum } from '@/types/MetricStructures';
import useAnalytics from '@/composables/useAnalytics';
import filterDeploymentTypeByConnectionCategory from '@/focus/deployment-type-selector/filterDeploymentTypeByConnectionCategory';
import useDeploymentTypes from '@/focus/deployment-type-selector/useDeploymentTypes';
import DeploymentTypeSelector from '@/focus/deployment-type-selector/DeploymentTypeSelector.vue';
import ChartManager from '@/chart-metric-definitions/ChartManager.vue';
import useIs5GSelected from '@/focus/composables/useIs5GSelected';
import useEndDate from '@/composables/useEndDate';

const { aggregation, locationId, metricSubtype } = useFilters(Dashboards.Focus);
const { cdns } = useCoreHealthByCdn();
const { chooseDifferentMetricSubtype } = useChooseDifferentMetricSubtype();
const { selectedOperators } = useSelectableNetworkOperators(Dashboards.Focus);
const { selectedChartConnectionCategories } = useFocusChartConnectionCategories();
const { availableMetrics, chartMetricsLabel, onChartMetricChange, selectedChartMetrics, selectedMetricSubtype } =
  useChartMetricSingleSelect<ChartMetricDefinitionWithSingleSubtype & { label: string }>('metricSubtype');
const { lookingAtTitle } = useLookingAtLocationTitle();
const { track } = useAnalytics();
const { currentEndDate } = useEndDate(Dashboards.Focus);

const deploymentTypeQueryParam = 'deploymentType';
const { selectedDeploymentType } = useDeploymentTypes(deploymentTypeQueryParam);
const is5GChartConnectionCategorySelected = useIs5GSelected(selectedChartConnectionCategories);

const { clearGeohashes, geohashes, onGeohashChange } = useGeohashesQueryParam();
const debouncedGeohashesRef = useDebouncedRef(geohashes, 1000);

const { actualGeocoding } = useGeocoding(Dashboards.Focus);
const enableHeavyDbPolygons = computed(() => {
  return actualGeocoding.value === 3;
});
const { computedLocationId, computedPolygonData, handleNewBounds, isDbScalingUp, mapIsLoading } = useMapWithPolygons(
  Dashboards.Focus,
  {
    enableGeohashes: enableHeavyDbPolygons,
  },
);

// skip network connection categories that are unselected or disabled
const validCategories = computed(() => {
  return selectedChartConnectionCategories.value.filter((category) => !category.disabled && category.selected);
});

chooseDifferentMetricSubtype(metricSubtype.value);
watch(metricSubtype, chooseDifferentMetricSubtype);

onMounted(() => {
  track('focus core health by operator cdn page view');
});
</script>

<template>
  <div v-if="locationId" class="focus-ch-details">
    <OnxPaper class="focus-ch-details__map-container">
      <div class="focus-ch-details__map-wrapper">
        <ChoroplethMap
          class="focus-ch-details__map"
          v-if="computedPolygonData !== null"
          :key="computedLocationId"
          v-model="computedLocationId"
          :geo-json="computedPolygonData"
          :choropleth-data="computedPolygonData.features"
          :display-color-scales="false"
          :polygon-fill-color-alpha="0.4"
          :disable-location-change-on-click="enableHeavyDbPolygons"
          enable-actions-on-polygons-without-data
          @newBounds="handleNewBounds"
          @feature-select="onGeohashChange"
        />

        <OnxButton v-if="geohashes.length > 0" class="map-geohash-reset-btn" @click="clearGeohashes">
          Reset geohash selection
        </OnxButton>
      </div>

      <DbScalingGrid v-if="isDbScalingUp" overlay target-display-name="Map" />
      <LoaderGrid v-else-if="mapIsLoading" overlay />
    </OnxPaper>

    <OnxHeadline as="h2" v-if="lookingAtTitle">{{ lookingAtTitle }}</OnxHeadline>

    <OnxSelectorBlock title="Chart Filters">
      <SingleMetricSelectorDropdown
        :selected-metric-subtype="selectedMetricSubtype"
        :available-metrics="availableMetrics"
        :selected-metric-label="chartMetricsLabel"
        @metric-change="onChartMetricChange"
      />
      <ChartConnectionCategoriesSelector />

      <DeploymentTypeSelector
        :disabled="!is5GChartConnectionCategorySelected"
        :query-param="deploymentTypeQueryParam"
      />
    </OnxSelectorBlock>

    <div class="onx-grid fluid fit">
      <template
        v-if="
          selectedChartMetrics.length > 0 &&
          selectedChartMetrics[0].structure === MetricStructuresEnum.ResolutionBreakdown
        "
      >
        <template v-for="operator in selectedOperators">
          <template
            v-for="category in validCategories"
            :key="`${selectedChartMetrics[0].metricSubtype}_${category.categoryValue}_${operator.name_mapped}`"
          >
            <CoreHealthVideoResolutionCharts
              :locationId="locationId"
              :aggregation="aggregation"
              :end-date="currentEndDate"
              :connection-category="category"
              :operator="operator"
              :cdns="cdns"
              :metric="selectedChartMetrics[0]"
              :deployment-type="filterDeploymentTypeByConnectionCategory(category, selectedDeploymentType)"
            />
          </template>
        </template>
      </template>
      <template v-else>
        <template v-for="operator in selectedOperators">
          <template v-for="connectionCategory in validCategories">
            <template
              v-for="metric in selectedChartMetrics"
              :key="`${operator.name_mapped}_${metric.metricSubtype}_${connectionCategory.categoryValue}`"
            >
              <ChartManager
                :dashboard="Dashboards.Focus"
                :metric="metric"
                :connection-category="connectionCategory.categoryValue"
                :connection-category-label="connectionCategory.categoryLabel"
                :deployment-type="filterDeploymentTypeByConnectionCategory(connectionCategory, selectedDeploymentType)"
                :location="locationId"
                :geohashes="debouncedGeohashesRef"
                :aggregation="aggregation"
                :end-date="currentEndDate"
                :operators="selectedOperators"
                :main-operator="operator"
              />
            </template>
          </template>
        </template>
      </template>
    </div>
  </div>
</template>
