<script setup lang="ts">
import { computed, ref } from 'vue';

import type { CompareToValues } from '@/constants/constants';

import IntlSpotlightBreadcrumbs from '@/intl-spotlight/IntlSpotlightBreadcrumbs.vue';
import OnxSpotlightHeaderNav from '@/components/onx/spotlight-header/OnxSpotlightHeaderNav.vue';

import useIntlSpotlightOverviewMetrics from '@/intl-spotlight/useIntlSpotlightOverviewMetrics';
import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';

import HtmlImageExport from '@/components/HtmlImageExport.vue';
import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';
import useSpotlightTilesImageExportTitle from '@/spotlight/useSpotlightTilesImageExportTitle';
import OnxPaper from '@/components/onx/OnxPaper.vue';
import OnxList from '@/components/onx/OnxList/OnxList.vue';
import OnxListItem from '@/components/onx/OnxList/OnxListItem.vue';
import OnxListItemText from '@/components/onx/OnxList/OnxListItemText.vue';
import DownloadFileIcon from '@/components/onx/icons/DownloadFileIcon.vue';
import MenuDotsIcon from '@/components/onx/icons/MenuDotsIcon.vue';
import OnxToggle from '@/components/onx/OnxToggle.vue';
import OnxVerticalDivider from '@/components/onx/OnxVerticalDivider.vue';

const matches = useBreakpoints();
const { exportTitle } = useSpotlightTilesImageExportTitle('Overview', true);

const htmlImageExportRef = ref();

const onHtmlImageExportListItemClick = () => {
  if (!htmlImageExportRef.value) {
    return;
  }

  htmlImageExportRef.value.print();
};

const getExportTarget = () => {
  return document.querySelector('[data-export-image-target]');
};

const { data } = useIntlSpotlightOverviewMetrics();
const queryParams = useSpotlightQueryParams();

const previousDate = computed(() => {
  if (!data.value || !('previous' in data.value.data.results[0])) {
    return '';
  }

  const compareTo = queryParams.agg.toValue() as CompareToValues;
  return data.value.data.results[0].previous?.[compareTo]?.date;
});
</script>

<template>
  <IntlSpotlightBreadcrumbs />
  <OnxSpotlightHeaderNav :previous-date="previousDate">
    <template #actions>
      <OnxToggle
        :model-value="queryParams.showScoreChange.isActive.value"
        @update:model-value="queryParams.showScoreChange.toggle"
        label="Show score change"
      />

      <OnxVerticalDivider :spacing="matches.desktop.value ? 16 : 8" />

      <HtmlImageExport
        v-show="matches.desktop.value"
        ref="htmlImageExportRef"
        :get-element="getExportTarget"
        :title="exportTitle"
      />

      <VDropdown
        v-if="matches.mobile.value && !matches.desktop.value"
        :distance="6"
        class="onx-navigation-header__settings"
      >
        <span>
          <MenuDotsIcon button />
        </span>

        <template #popper>
          <OnxPaper :depth="3">
            <OnxList>
              <OnxListItem extra-x-padding @click.prevent="onHtmlImageExportListItemClick">
                <OnxListItemText size="sm">Save as image</OnxListItemText>
                <template #right>
                  <DownloadFileIcon />
                </template>
              </OnxListItem>
            </OnxList>
          </OnxPaper>
        </template>
      </VDropdown>
    </template>
  </OnxSpotlightHeaderNav>
</template>
