<script setup>
import RankingTable from '@/components/comparison/RankingTable';
import ScoreChangeTable from '@/components/comparison/ScoreChangeTable';
import OnxPaper from '@/components/onx/OnxPaper';

const props = defineProps({
  ...RankingTable.props,
  showScoreChange: {
    type: Boolean,
    default: false,
  },
  hideContent: {
    type: Boolean,
    default: false,
  },
  hideActions: {
    type: Boolean,
    default: false,
  },
  compareTo: {
    type: String,
    required: false,
  },
});

const emit = defineEmits(['ranking-table:click', 'header:click']);
</script>

<template>
  <OnxPaper class="onx-metric-box">
    <div
      v-if="$slots.header"
      class="onx-metric-box__header"
      :class="{
        'onx-metric-box__header--no-border': !ranks.length || hideContent,
      }"
      @click="emit('header:click')"
    >
      <slot name="header" />
    </div>

    <slot v-if="ranks.length && !hideContent">
      <div class="onx-metric-box__content" :class="{ 'onx-metric-box__content--inset': props.hideRank }">
        <RankingTable
          v-if="!showScoreChange"
          :bigger-better="props.biggerBetter"
          :ranks="props.ranks"
          :min-and-max="props.minAndMax"
          :is-variable-metric-category="props.isVariableMetricCategory"
          :hide-rank="props.hideRank"
          @click="emit('ranking-table:click', $event)"
          :show-confidence-intervals="props.showConfidenceIntervals"
        />
        <ScoreChangeTable v-else-if="compareTo" :ranks="props.ranks" :compare-to="compareTo" />
      </div>
    </slot>

    <div v-if="$slots.footer && !hideActions" class="onx-metric-box__footer">
      <slot name="footer" />
    </div>
  </OnxPaper>
</template>
