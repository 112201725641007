<template>
  <div ref="chartTooltip" :class="posClass" :style="tooltipStyle" class="RankingTableTooltip">
    <div v-if="display" class="RankingTableTooltip__wrapper">
      <div v-if="date || title" class="RankingTableTooltip__header">
        <div v-if="date" class="RankingTableTooltip__date">
          {{ date }}
        </div>
        <div class="RankingTableTooltip__headerLine">
          <div class="RankingTableTooltip__headerLineLeft">
            <div v-if="title" class="RankingTableTooltip__title">
              {{ title }} <span v-if="unit">| {{ unit }}</span>
            </div>
            <div v-if="aggregation" class="RankingTableTooltip__aggregation">({{ aggregation }})</div>
          </div>
          <div v-if="showCompare" class="RankingTableTooltip__headerLineRight">
            <div class="RankingTableTooltip__header-right-title">National Values</div>
          </div>
          <div v-else-if="secondaryTitle" class="RankingTableTooltip__headerLineRight">
            <div class="RankingTableTooltip__header-right-title">{{ secondaryTitle }}</div>
          </div>
        </div>
      </div>
      <simple-ranking-table squared background-transparent>
        <ranking-table-item
          v-for="datum in tooltipDatapoints"
          :key="datum.label"
          :value="datum.value"
          :uci="datum.uci"
          :lci="datum.lci"
          :hint="datum.hint"
          :rank="datum.rank"
          :confidence-hidden="!datum.uci && !datum.lci"
          :operator-initial="Array.isArray(datum.color) ? '-' : datum.label && datum.label[0]"
          :operator-color="Array.isArray(datum.color) ? colorBrandBlue : datum.color"
          :operator-name="datum.label"
          :operator-is-mvno="!!datum.operator?.is_mvno"
          :rank-hidden="rankHidden"
          :show-secondary-value="showCompare || showSecondaryValue"
          :secondary-value="datum.compare?.value || datum.secondaryValue"
          :compare-lci="datum.compare && datum.compare.lci"
          :compare-uci="datum.compare && datum.compare.uci"
        />
      </simple-ranking-table>
    </div>
  </div>
</template>
<script>
import { format } from 'date-fns';
import uniqBy from 'lodash/uniqBy';
import RankingTableItem from '@/components/comparison/RankingTableItem';
import SimpleRankingTable from '@/components/comparison/SimpleRankingTable';
import { API_LONG_DATE_FORMAT } from '@/constants/dateFormats';
import colors from '@/utils/colorPalette';
import { getSafeDate } from '@/utils/date';

export default {
  name: 'RankingTableTooltip',
  components: { SimpleRankingTable, RankingTableItem },
  props: {
    posClass: {
      type: String,
      default: '',
    },
    tooltipStyle: {
      type: Object,
      default: () => ({}),
    },
    datapoints: {
      type: Array,
      default: () => [],
    },
    secondaryTitle: {
      type: String,
      default: '',
    },
    showSecondaryValue: {
      type: Boolean,
      default: false,
    },
    showCompare: {
      type: Boolean,
      default: false,
    },
    display: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    aggregation: {
      type: String,
      default: '',
    },
    unit: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: undefined,
    },
    rankHidden: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      colorBrandBlue: colors.colorBrandBlue,
    };
  },
  computed: {
    displayDate() {
      return this.datapoints[0] && format(getSafeDate(this.datapoints[0].x), API_LONG_DATE_FORMAT);
    },
    tooltipDatapoints() {
      return uniqBy(this.datapoints, (point) => {
        return point.label;
      });
    },
  },
};
</script>

<style lang="scss">
@use 'scss/variables.module' as *;
@use 'foundation-sites/scss/foundation' as *;

$padding: 0.4rem;

.RankingTableTooltip {
  position: absolute;
  background: transparent;
  color: $color-text-black;
  transition: all 0.1s ease;
  pointer-events: none;
  font-size: $font-size-13;
  padding: 10px;
  margin: 0;
  z-index: 1;

  &__wrapper {
    background-color: $color-white-mate;
  }

  &__header {
    padding: $padding;
    background-color: transparent;
    border: 1px solid $color-gray-border-table;
    border-bottom: 0;
    min-width: 310px;
  }

  &__date {
    padding: $padding;
    font-size: $font-size-10;
    font-weight: $font-weight-roboto-medium;
  }

  &__headerLine {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    white-space: nowrap;
  }
  &__headerLineLeft {
    display: flex;
    flex-wrap: nowrap;
    flex: 1 0;
  }
  &__headerLineRight {
    flex: 0 0 100px;
    text-align: right;
  }

  &__title {
    color: $color-blue-header;
    padding: $padding;
    font-size: $font-size-14;
    font-weight: $font-weight-roboto-bold;
  }

  &__aggregation {
    color: $color-blue-header;
    padding: 0 $padding $padding $padding;
    font-size: $font-size-12;
  }

  &__header-right-title {
    color: $color-blue-header;
    padding: $padding;
    font-size: $font-size-12;
    line-height: 16px;
  }

  &--fixed {
    &.RankingTableTooltip {
      left: 0;
      bottom: 0;
      z-index: 1;
      padding: 0;
    }

    .RankingTableTooltip__header {
      border: 0;
    }

    .SimpleRankingTable__table {
      margin: 0;
    }

    .SimpleRankingTable__body {
      border: 0;
    }
  }
  &--right {
    &.RankingTableTooltip {
      left: auto !important;
      right: 0 !important;
      bottom: 0;
      z-index: 10000;
      padding: 0;
    }

    .RankingTableTooltip__header {
      border: 0;
    }

    .SimpleRankingTable__table {
      margin: 0;
    }

    .SimpleRankingTable__body {
      border: 0;
    }
  }
}
</style>
