<script setup lang="ts">
import { computed } from 'vue';
import InfoIcon from '@/components/onx/icons/InfoIcon.vue';
import OnxDelta from '@/components/onx/OnxDelta.vue';
import OperatorAvatar from '@/components/OperatorAvatar.vue';
import CustomTooltip from '@/components/tooltip/CustomTooltip.vue';
import { COMPARE_TO, CompareToValues } from '@/constants/constants';

type Props = {
  compareTo: CompareToValues;
  ranks: any[];
  hideVsOthers?: boolean;
  getOperatorColor?: (datum: any) => string;
  getOperatorName?: (datum: any) => string;
  getOperatorIsMvno?: (datum: any) => boolean;
};

const props = defineProps<Props>();

const tooltipMessage = computed(() => {
  const compareTo = COMPARE_TO[props.compareTo]!.label;
  const numberOfComponents = props.hideVsOthers ? 'two' : 'three';

  return `
      <p>Score & Rank Change aims at showing changes in scores or ranks of experiential metrics. There are ${numberOfComponents} components:</p>
      <ul class="list-styled">
        <li>Rank change: Change in individual network rank compared to ${compareTo}.</li>
        <li>Score change: Change in individual network scores compared to ${compareTo}.</li>
        ${
          props.hideVsOthers
            ? ''
            : '<li>Change vs. Others: Change in individual network scores compared to the average of all networks.</li>'
        }
      </ul>
    `;
});
</script>

<template>
  <div class="ScoreChangeTable" :class="{ 'ScoreChangeTable--hide-vs-others': hideVsOthers }">
    <template v-for="datum in ranks" :key="datum.operatorName">
      <OperatorAvatar
        :background-color="datum.operatorColor || datum.operator?.hex_color"
        :name="datum.operatorName || datum.operator?.name_mapped"
        :background-style="datum.operatorIsMvno || datum.operator?.is_mvno ? 'outline' : 'fill'"
      />

      <div class="ScoreChangeTable__value">
        {{ datum.value }}
      </div>

      <OnxDelta v-for="(score, index) in datum.scoreChange" :key="index" :value="score" />
    </template>

    <div>
      <CustomTooltip :message="tooltipMessage">
        <InfoIcon />
      </CustomTooltip>
    </div>
    <div></div>
    <div><small>Rank change</small></div>
    <div><small>Change</small></div>
    <div v-if="!hideVsOthers"><small>vs. Others</small></div>
  </div>
</template>

<style scoped lang="scss">
@use 'scss/variables.module' as *;
@import 'scss/mixins';

$row-height: 28px;

.ScoreChangeTable {
  display: grid;
  grid-template-columns: [operator] 28px [value] 1fr [rank_change] 1fr [change] 1fr [vs_others] 1fr;
  grid-template-rows: auto;
  grid-auto-rows: $row-height;
  grid-gap: 16px;
  row-gap: 20px;
  align-items: center;

  width: 100%;
  padding: 0 16px 0 8px;

  &--hide-vs-others {
    grid-template-columns: [operator] 28px [value] 1fr [rank_change] 1fr [change] 1fr;
  }

  small {
    font-size: $font-size-10;
    color: var(--charcoal-200);
  }
}

.ScoreChangeTable__value {
  font-size: $font-size-14;
}
</style>
