<template>
  <div class="image-export">
    <a ref="download" :download="`${filenameBeforeExtension}.png`" @click.stop="download">
      <slot>
        <CameraIcon />
      </slot>
    </a>
  </div>
</template>

<script>
import CameraIcon from '@/components/onx/icons/CameraIcon.vue';
import logo from '@/assets/onx/logo/opensignal.svg';
import useImageExport from '@/image-export/useImageExport';

export default {
  name: 'ImageExport',
  components: {
    CameraIcon,
  },
  props: {
    filenameBeforeExtension: {
      type: String,
      default: 'export',
    },
    /** Title, subtitle, and info line */
    content: {
      type: Array,
      default: () => [],
    },
    canvasContainerNode: {
      type: Object,
      default: () => ({}),
    },
    product: {
      type: String,
      default: 'performance',
    },
    selectedPoint: {
      type: Array,
      default: () => [],
    },
    confidenceState: {
      type: Boolean,
      default: false,
    },
    legendDisabled: {
      type: Boolean,
      default: false,
    },
    legendTitle: {
      type: String,
      default: undefined,
    },
    fontSize: {
      type: Number,
      default: 24,
    },
  },
  setup(props) {
    const { exportImage } = useImageExport(props.product, !props.legendDisabled);

    return {
      exportImage,
    };
  },
  data() {
    return {
      debug: false,
      icons: {
        logo,
      },
      dpi: window.devicePixelRatio || 1,
      scale: window.devicePixelRatio < 2 ? 2 : 1,
      picture: null,
      OFF_SET: 10,
      WATERMARK_PADDING: 10,
      TOP_PADDING: 20,
    };
  },
  computed: {
    original() {
      return this.canvasContainerNode.querySelector('canvas');
    },
    singleNetworkLegend() {
      return (
        this.selectedPoint.length > 1 &&
        this.selectedPoint[0].canonical_network_id === this.selectedPoint[1].canonical_network_id
      );
    },
  },
  methods: {
    async download() {
      if (this.$refs.download.href) return;

      const legend = this.selectedPoint.map((l) => {
        let color = l.hex_color;

        if (!color) {
          color = '#dee4ec';
        } else if (!CSS.supports('color', color) && color.length === 6) {
          color = `#${color}`;
        }

        return {
          ...l,
          value: l.y,
          label: l.name_mapped,
          color,
        };
      });

      this.exportImage({
        title: this.content[0],
        filename: `${this.filenameBeforeExtension}.png`,
        subtitle: this.content?.[1],
        info: this.content?.[2],
        legend,
        legendTitle: this.legendTitle || (this.singleNetworkLegend ? 'CDNs' : 'Operators'),
        chartId: this.original.id,
        showConfidenceInterval: this.confidenceState,
      });
    },
  },
};
</script>
<style lang="scss">
@use 'scss/variables.module' as *;
@import 'scss/components';

.image-export {
  @extend %boxIcon;
  color: $color-blue-header;
  &:hover {
    color: $color-white;
    background-color: $color-blue-header;
  }
}
</style>
