<script setup lang="ts">
import { ref, onMounted, watchEffect, watch } from 'vue';
import { onBeforeRouteUpdate, RouterView, useRouter } from 'vue-router';

import { useStore } from 'vuex';
import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';
import OnxSidebar from '@/components/onx/OnxSidebar.vue';
import { DEFAULT_USER_GROUP } from '@/constants/constants';
import { Dashboards } from '@/constants/dashboards';
import useAnalytics from '@/composables/useAnalytics';
import useUser from '@/components/onx/composables/useUser';
import useDashboardInfo from '@/composables/useDashboardInfo';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';
import IntlSpotlightMenu from '@/intl-spotlight/IntlSpotlightMenu.vue';
import Routes from '@/constants/routes';

const vuexStore = useStore();
const mainMetric = vuexStore.getters['metrics/primaryMetric'];
const userGroup = vuexStore.getters['competitive/userGroup'];

const dashboard = useCurrentDashboardName();
const { data: dashboardInfo } = useDashboardInfo(dashboard.value);

const { track } = useAnalytics();
const matches = useBreakpoints();

const router = useRouter();

const ready = ref(false);

onBeforeRouteUpdate((to, from) => {
  if (from.name === to.name) {
    track('multicountry-spotlight:route-update', {
      dashboard: 'spotlight',
      params: to.params,
      query: to.query,
      url: to.path,
      route: to.name,
    });
  }
});

const { setUser, setUserSettings, user } = useUser();
onMounted(async () => {
  if (!user.value.id) {
    const res = await setUser();

    if (res) {
      vuexStore.dispatch('checkDisclaimer');
    }
  }

  await setUserSettings(Dashboards.Spotlight);

  ready.value = true;
});

watch(
  () => mainMetric,
  () => {
    vuexStore.dispatch('setUserGroup', mainMetric?.subcategory || userGroup || DEFAULT_USER_GROUP);
  },
);

watchEffect(() => {
  if (!dashboardInfo.value) {
    return;
  }

  if (!dashboardInfo.value.data.include_international_spotlight) {
    router.push({ name: Routes.CompetitiveOverview, query: router.currentRoute.value.query });
  }
});
</script>

<template>
  <div class="spotlight">
    <div v-if="matches.laptop.value" class="spotlight__sidebar">
      <OnxSidebar>
        <IntlSpotlightMenu />
      </OnxSidebar>
    </div>

    <div v-if="ready" class="spotlight__main" data-export-image-target>
      <RouterView name="header" />
      <div class="intl-spotlight__content">
        <RouterView />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@use 'scss/onx-breakpoints.module' as breakpoints;
.intl-spotlight {
  &__content {
    margin-top: -8px;

    @include breakpoints.tablet {
      margin-top: -16px;
    }
  }
}
</style>
