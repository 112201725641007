import { computed, MaybeRef, unref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const useQueryParamSingleSelect = <T extends string>(queryParam: string, defaultValue: MaybeRef<T>) => {
  const route = useRoute();
  const router = useRouter();

  const selectedValue = computed<T>(() => {
    const queryParamValue = route.query[queryParam];

    if (!queryParamValue) {
      return unref(defaultValue);
    } else {
      return queryParamValue as T;
    }
  });

  const onChange = (changed: T, replace?: boolean) => {
    const payload = {
      query: {
        ...route.query,
        [queryParam]: changed,
      },
    };

    if (replace) {
      return router.replace(payload);
    } else {
      return router.push(payload);
    }
  };

  const reset = () => {
    return router.replace({
      query: {
        ...route.query,
        [queryParam]: unref(defaultValue),
      },
    });
  };

  const toValue = () => {
    return selectedValue.value;
  };

  return {
    onChange,
    selectedValue,
    reset,
    toValue,
  };
};

export default useQueryParamSingleSelect;
