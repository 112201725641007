<script setup>
import { useAuth0 } from '@auth0/auth0-vue';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useActions } from 'vuex-composition-helpers';

import { createNamespacedHelpers } from 'vuex-composition-helpers';
import LoaderGrid from '../LoaderGrid.vue';
import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';
import BurgerIcon from '@/components/onx/icons/BurgerIcon';
import CheckmarkIcon from '@/components/onx/icons/CheckmarkIcon';
import ChevronDownIcon from '@/components/onx/icons/ChevronDownIcon';
import LogoutIcon from '@/components/onx/icons/LogoutIcon';
import QuestionIcon from '@/components/onx/icons/QuestionIcon';
import { OnxList, OnxListItem } from '@/components/onx/OnxList';
import OnxPaper from '@/components/onx/OnxPaper';
import ROUTES from '@/constants/routes';
import useAnalytics from '@/composables/useAnalytics';

const { useGetters: useAuthGetters } = createNamespacedHelpers(['auth']);
const { getOnxProducts: apps } = useAuthGetters(['getOnxProducts']);
const { resetUser } = useActions(['resetUser']);
const { navigateToDashboard } = useActions(['navigateToDashboard']);

const route = useRoute();
const router = useRouter();

const matches = useBreakpoints();

const { logout } = useAuth0();

const { track } = useAnalytics();

const selectedApp = computed(() => {
  let matchingApp = {};

  apps.value.forEach((app) => {
    const hasMatch = route.matched.some((record) => {
      return app.matchingRoutes?.includes(record.name);
    });

    if (hasMatch) {
      matchingApp = app;
    }
  });

  return matchingApp;
});

const isSwitching = ref(false);
const selectApp = async ($event, app) => {
  if (app.name === selectedApp.value.name) {
    return;
  } else if (isSwitching.value) {
    return;
  }

  track('app switch', { from: selectedApp.value.name, to: app.name });
  if (app.route.match(/^http/)) {
    window.open(app.route);
  } else {
    isSwitching.value = true;
    try {
      await navigateToDashboard({ mode: app.route });
    } finally {
      isSwitching.value = false;
    }
  }
};

const signOut = () => {
  resetUser();
  return logout({
    returnTo: window.location.origin + router.resolve({ name: ROUTES.LogoutCallback }).href,
  });
};
</script>

<template>
  <VDropdown
    :distance="6"
    :popper-hide-triggers="['click']"
    :placement="matches.laptop.value ? 'bottom-start' : 'bottom-end'"
  >
    <div class="onx-app-switch">
      <div class="onx-app-switch__header">
        <template v-if="matches.laptop.value">
          <img :src="selectedApp.logo" />

          <ChevronDownIcon class="onx-app-switch__headerRipple" small />
        </template>

        <BurgerIcon v-else class="onx-app-switch__burgerIcon" small />
        <LoaderGrid v-if="isSwitching" size="sm" />
      </div>
    </div>
    <template #popper>
      <OnxPaper :depth="3">
        <OnxList>
          <OnxListItem
            v-for="app in apps"
            :key="app.name"
            class="onx-app-switch__item"
            large
            :disabled="!app.enabled"
            @click="selectApp($event, app)"
          >
            <template #left>
              <CheckmarkIcon :style="{ visibility: selectedApp.name === app.name ? 'visible' : 'hidden' }" />
            </template>

            <img :src="app.logo" />
          </OnxListItem>

          <OnxListItem
            v-if="!matches.laptop.value"
            large
            @click="
              selectApp($event, {
                url: 'https://cdn.opensignal.com/public/data/reports/pdf-only/data-2022-09/5gglobalmobilenetworkexperienceawards_opensignal2022.pdf',
              })
            "
          >
            <template #left>
              <QuestionIcon />
            </template>
            Help
          </OnxListItem>

          <OnxListItem v-if="!matches.laptop.value" large @click="signOut">
            <template #left>
              <LogoutIcon />
            </template>
            Logout
          </OnxListItem>
        </OnxList>
      </OnxPaper>
    </template>
  </VDropdown>
</template>

<style lang="scss">
@use 'scss/variables.module' as *;
@import 'scss/onx-breakpoints.module';

.onx-app-switch {
  height: 48px;
  padding: 12px 16px;
  display: flex;
  align-items: center;

  &:hover {
    background: var(--light);
  }

  @include laptop {
    margin-right: pxToRem(16);
  }
}

.onx-app-switch__header {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  position: relative;

  img {
    height: 24px;
  }
}

.onx-app-switch__headerRipple {
  flex: 0 0 16px;
  margin-left: 10px;
  transition: transition();

  @include laptop {
    flex: 0 0 24px;
    margin-left: 16px;
  }

  .v-popper--shown & {
    transform: rotate(-180deg);
  }
}
.onx-app-switch__burgerIcon {
  color: var(--charcoal-300);
}

.onx-app-switch__item {
  padding-right: 24px;

  img {
    height: 24px;
  }
}
</style>
