import axios from 'axios';
import auth0 from '@/auth0';
import { LOCAL_STORAGE_KEYS } from '@/constants/constants';

const osApi = axios.create({
  baseURL: `${import.meta.env.VITE_BASE_URL}api/v2`,
  paramsSerializer: {
    serialize: (params) => {
      const searchParams = new URLSearchParams();
      for (const [key, value] of Object.entries(params)) {
        if (value === null || value === undefined) {
          continue;
        }

        if (Array.isArray(value)) {
          if (value.length === 0) {
            continue;
          }

          searchParams.append(key, value.join(','));
        } else {
          searchParams.append(key, value);
        }
      }

      return searchParams.toString();
    },
  },
});

osApi.interceptors.request.use((config) => {
  const token = window.localStorage.getItem(LOCAL_STORAGE_KEYS.OS_TOKEN);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

osApi.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if ([401, 403].includes(error.response?.status)) {
      return auth0.loginWithRedirect();
    }

    return Promise.reject(error);
  },
);

export default osApi;
