<script lang="ts" setup>
import { computed, toRefs, unref } from 'vue';
import WorldIcon from '../../icons/WorldIcon.vue';
import RacehorseLane from './RacehorseLane.vue';
import { RacehorseScore } from './types';
import OnxPaper from '@/components/onx/OnxPaper.vue';
import OnxHeadline from '@/components/onx/typography/OnxHeadline.vue';
import { Operator } from '@/types/Operator';
import NoData from '@/components/NoData.vue';
import LoaderGrid from '@/components/LoaderGrid.vue';

type Props = {
  chartTitle: string;
  scoresByLabel: Array<{
    label: string;
    scores: RacehorseScore<Operator>[];
  }>;
  minScore: number;
  maxScore: number;
  isLoading?: boolean;
};

const props = defineProps<Props>();
const { maxScore, minScore, scoresByLabel } = toRefs(props);
const sortedScoresByLabel = computed(() => {
  return unref(scoresByLabel).sort((a, b) => a.label.localeCompare(b.label));
});
const noData = computed(() => {
  return unref(scoresByLabel).length === 0;
});
</script>

<template>
  <OnxPaper class="racehorse-chart__page">
    <div class="racehorse-chart__header onx-metric-box__header">
      <div class="racehorse-chart__header__icon">
        <WorldIcon />
      </div>
      <OnxHeadline as="h4" class="racehorse-chart__header__title">{{ chartTitle }}</OnxHeadline>
    </div>
    <div class="racehorse-chart__body">
      <LoaderGrid v-if="isLoading" />
      <NoData v-if="!isLoading && noData" />
      <table v-if="!noData && !isLoading">
        <colgroup>
          <col />
          <col style="width: 60%" />
        </colgroup>
        <tbody>
          <tr v-for="{ label, scores } in sortedScoresByLabel" :key="label" class="racehorse-chart__lane">
            <td class="racehorse-chart__lane__label">{{ label }}</td>
            <td class="racehorse-chart__lane__figure">
              <RacehorseLane :min-score="minScore" :max-score="maxScore" :scores="scores" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </OnxPaper>
</template>

<style lang="scss">
@use 'scss/variables.module' as *;
@import 'scss/onx-breakpoints.module';

.racehorse-chart {
  &__page {
    width: 250px;

    @include tablet {
      width: 400px;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    padding: 0 8px;
    gap: 8px;
    border-bottom: 1px solid var(--onx-divider-color);

    &__icon {
      height: 24px;
      width: 24px;
      line-height: 24px;
    }

    &__title {
      flex-grow: 1;
    }
  }

  &__body {
    position: relative;
    width: 250px;
    min-height: 75px;
    max-height: 476px;
    overflow-y: auto;
    font-size: small;
    padding: 1rem;
    padding-top: 8px;

    @include tablet {
      width: 400px;
    }

    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;

      tbody {
        border: 0;
      }

      td {
        padding: 0;
      }

      .racehorse-chart__lane__label,
      .racehorse-chart__lane__figure {
        vertical-align: middle;
        position: relative;
      }

      .racehorse-chart__lane__label {
        padding-right: 1rem !important;
      }
    }
  }
}
</style>
