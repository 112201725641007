import * as Sentry from '@sentry/vue';
import { isBefore } from 'date-fns';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import mp from 'mixpanel-browser';
import { Config, Maps, Overview, Trends } from './api';
import {
  locations,
  mapData,
  operators,
  nationalStats,
  overviewStats,
  connectionStats,
  polygons,
  hero,
  chart,
} from './arrayModules';
import competitive from './modules/competitive';
import metrics from './modules/metrics';
import { ciConfig, shapes, user, dashboardInfo } from './treeModules';
import osApi from '@/api/osApi';
import { DEFAULT_DASHBOARD, LOCAL_STORAGE_KEYS, OPERATORS_COUNTRY_OVERRIDES } from '@/constants/constants';
import ROUTES from '@/constants/routes';
import router from '@/router';
import { networksWithColors } from '@/utils/config';
import { get5stepsPercentiles } from '@/utils/data';
import { getSafeDate } from '@/utils/date';
import { captureException } from '@/utils/error';
import { getDatumValue } from '@/utils/viewHelpers';
import ROUTE_GROUPS from '@/constants/routeGroups';
import auth0 from '@/auth0';

const types = {
  AUTH_LOAD_USER_ASYNC_FAILURE: 'auth/LOAD_USER_ASYNC_FAILURE',
  AUTH_LOAD_USER_ASYNC_PENDING: 'auth/LOAD_USER_ASYNC_PENDING',
  AUTH_LOAD_USER_ASYNC_SUCCESS: 'auth/LOAD_USER_ASYNC_SUCCESS',
  AUTH_LOGIN_ASYNC_FAILURE: 'auth/LOGIN_ASYNC_FAILURE',
  AUTH_LOGIN_ASYNC_PENDING: 'auth/LOGIN_ASYNC_PENDING',
  AUTH_LOGIN_ASYNC_SUCCESS: 'auth/LOGIN_ASYNC_SUCCESS',
  SET_OPERATOR_VISIBILITY: 'charts/SET_OPERATOR_VISIBILITY',
  SELECTED_NETWORK_OPERATORS: 'charts/selectedNetworkOperators',
};

const dashboardLabels = {
  competitive: 'Spotlight',
  spotlight: 'Spotlight',
  focus: 'Focus',
  onx360: '360',
  360: '360',
};
const getDashboardLabel = (dashboard) => {
  if (dashboardLabels.hasOwnProperty(dashboard)) {
    return dashboardLabels[dashboard];
  } else {
    return dashboard;
  }
};

export const StoreActionsConstructor = ({ config, maps, overview, trends }) => ({
  // move to user store all together
  // actions file should only cover generic modules
  authenticate({ commit }, { auth0 }) {
    commit(types.AUTH_LOGIN_ASYNC_PENDING);
    return auth0
      .getAccessTokenSilently()
      .then((token) => {
        window.localStorage.setItem(LOCAL_STORAGE_KEYS.OS_TOKEN, token);
        commit(types.AUTH_LOGIN_ASYNC_SUCCESS, true);
        config = new Config(token);
        trends = new Trends(token);
        overview = new Overview(token);
        maps = new Maps(token);

        osApi.defaults.headers.common['authorization'] = `Bearer ${token}`;
      })
      .catch((err) => {
        commit(types.AUTH_LOGIN_ASYNC_FAILURE, err);
        return Promise.reject(err);
      });
  },

  // mixpanel tracking action
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  trackRoute({ getters, state }, dashboard) {
    mp.track(getDashboardLabel(dashboard), {
      // locationId is for maintaining history, TODO release removal on 01.01.2020
      locationId: router.currentRoute.value.query.location,
      ...router.currentRoute.value.query,
      url: router.currentRoute.value.path,
      route: router.currentRoute.value.name,
      organization: getters.user.organization,
      username: getters.user.username,
      locationName: getters['location/currentLocation'].name,
      locationCountry: getters['location/currentLocation'].iso3,
      metricSubtype: getters['metrics/primaryMetric'].subtype,
      metricType: getters['metrics/primaryMetric'].type,
    });
  },

  // mixpanel tracking action
  track({ getters }, { dashboard, query, route, url }) {
    const [metricSubtype, metricType] = query.metric ? query.metric.split('_') : [null, null];

    // temporary needed as ci map page doesn't have location on route
    const location = query.location || getters.ciConfig.location;
    const { iso3, name } = getters.locations.find((l) => location === l.key);

    mp.track(getDashboardLabel(dashboard), {
      // locationId is for maintaining history, TODO release removal on 01.01.2020
      locationId: location,
      ...query,
      // temporary needed as ci map page doesn't have location on route
      location: location,
      url,
      route,
      organization: getters.user.organization,
      username: getters.user.username,
      locationName: name,
      locationCountry: iso3,
      metricSubtype,
      metricType,
    });
  },

  // move to user store all together
  // actions file should only cover generic modules
  resetUser({ commit }) {
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.OS_TOKEN);
    config = new Config();
    trends = new Trends();
    overview = new Overview();
    maps = new Maps();
    commit(user.types.SET, {});
    commit(chart.types.SET, { array: [] });
    commit(overviewStats.types.SET, { array: [] });
    commit(operators.types.SET, []);

    mp.reset();
  },

  setMGParams({ commit }, params) {
    if (params.metric) {
      commit(metrics.types.SET_PRIMARY, params.metric);
    }
  },

  // used in CI
  setRouteParam({ commit }, args) {
    Object.keys(args).map((key) => {
      commit(ciConfig.types.SET_KEY, { key: key, data: args[key] });
    });
  },

  // used in CI
  setCiTrend({ commit, dispatch }, { agg, country, countryISO3, date, location, metric }) {
    commit(chart.types.SET_CHART_PENDING);
    commit(hero.types.SET_CHART_PENDING);
    commit(locations.types.SET_CURRENT, location);
    commit(competitive.types.SET_WARNING, false);

    return Promise.all([
      trends.getTrends({
        metric: metric,
        location: location,
        agg: agg,
        days: 365,
        dashboard: DEFAULT_DASHBOARD,
        comparison: false,
      }),
      trends.getTrends({
        metric: metric,
        location: country,
        agg: agg,
        days: 365,
        dashboard: DEFAULT_DASHBOARD,
        comparison: false,
      }),
    ])
      .then((response) => {
        commit(chart.types.SET, {
          array: response[0].results,
          metric: response[0].metric,
        });
        // filter national data to data available
        const minDate = get(
          response[0].results.find((a) => Number.isFinite(getDatumValue(a))),
          'date',
          date,
        );

        const reversedResponse = [...response[0].results];
        reversedResponse.reverse();
        const maxDate = get(
          reversedResponse.find((a) => Number.isFinite(getDatumValue(a))),
          'date',
          date,
        );

        commit(hero.types.SET, {
          array: response[1].results.filter((p) => !isBefore(getSafeDate(p.date), getSafeDate(minDate))),
          metric: response[1].metric,
        });
        const newOperators = networksWithColors(Object.values(response[0].operators));
        dispatch('setCiOperators', { newOperators, countryISO3 });
        // We have to explicitly set chart operators to display the relevant operators in sidebar
        dispatch(
          'setChartOperators',
          newOperators.map((operator) => operator.canonical_network_id),
        );
        commit(chart.types.SET_CHART_SUCCESS);
        commit(hero.types.SET_CHART_SUCCESS);

        if (
          !response[0].results.find((a) => a.date === date && Number.isFinite(getDatumValue(a))) &&
          minDate !== date
        ) {
          commit(competitive.types.SET_WARNING, true);
          router.replace({
            name: ROUTES.CompetitiveDetails,
            query: {
              ...router.currentRoute.value.query,
              endDate: maxDate,
            },
          });

          return false;
        }

        return true;
      })
      .catch((e) => {
        captureException(e);

        commit(chart.types.SET_CHART_FAILURE);
        commit(hero.types.SET_CHART_FAILURE);
        commit(locations.types.SET_CURRENT, '');
      });
  },

  // used in CI
  fetchOlderCiTrends({ commit, state }, { agg, country, days = 365, endDate, location, metric }) {
    commit(chart.types.SET_CHART_PENDING);

    return Promise.all([
      trends.getTrends({
        metric: metric,
        location: location,
        agg: agg,
        days,
        dashboard: DEFAULT_DASHBOARD,
        comparison: false,
        date: endDate,
      }),
      trends.getTrends({
        metric: metric,
        location: country,
        agg: agg,
        days,
        dashboard: DEFAULT_DASHBOARD,
        comparison: false,
        date: endDate,
      }),
    ])
      .then((response) => {
        commit(chart.types.SET, {
          array: [...response[0].results, ...state.chart.array],
          metric: response[0].metric,
        });

        commit(chart.types.SET_CHART_SUCCESS);
      })
      .catch((e) => {
        captureException(e);
      });
  },

  /**
   * Should be run when first loading a spotlight page, like overview or by geography. 
   * It sets the initial visibility of operators based on the home operator and the operators
   * loaded for the page. MVNOs that don't yet have visibility set will be hidden by default.
   * The home operator will be visible by default;
   * @param responseOperators: A set of operators loaded for this page
   * @param homeOperator: The home operator for the user (optional)
   */
  setInitialOperatorVisibility({ commit }, { homeOperator, responseOperators }) {
    const operatorVisibilityFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_KEYS.OPERATOR_VISIBILITY); // will be null if not set
    const operatorVisibility = operatorVisibilityFromLocalStorage !== null ? JSON.parse(operatorVisibilityFromLocalStorage) : {};

    for (const operator of Object.values(responseOperators)) {
      if (operator.is_mvno && !operatorVisibility.hasOwnProperty(operator.canonical_network_id)) {
        operatorVisibility[operator.canonical_network_id] = false;
      } else if (!operatorVisibility.hasOwnProperty(operator.canonical_network_id)) {
        operatorVisibility[operator.canonical_network_id] = true;
      }
    }
    operatorVisibility[homeOperator.canonical_network_id] = true; // override MVNOs for the home network
    commit(types.SET_OPERATOR_VISIBILITY, operatorVisibility);
  },

  // used in Spotlight for the first page. It can occasionally be called multiple times during the lifespan
  // of the application.
  setOverview({ commit, dispatch, getters, state }, { dashboard = DEFAULT_DASHBOARD, date, location, metricString }) {
    metricString.split(',').map((m) => {
      commit(overviewStats.types.SET_CHART_PENDING, m);
    });
    commit(overviewStats.types.SET, { array: [] });
    commit(locations.types.SET_CURRENT, location);

    return overview
      .getDataByLocation(location, date, metricString, dashboard, true)
      .then((response) => {
        metricString.split(',').map((m) => {
          commit(overviewStats.types.SET_CHART_SUCCESS, m);
        });

        const responseOperators = response.operators || [];
        commit(overviewStats.types.SET_OVERVIEW, [...getters['overviewStats'], ...response.results]);
        commit(operators.types.SET, networksWithColors(Object.values(responseOperators)));

        const homeOperator = getters['charts/homeNetwork']; // { ...,  canonical_network_id: number, ...}
        dispatch('setInitialOperatorVisibility', { responseOperators, homeOperator });

        commit(metrics.types.SET_SUPPORTING, state.ciConfig.data.my);
      })
      .catch((e) => {
        captureException(e);
        commit(overviewStats.types.SET_CHART_FAILURE);
        commit(locations.types.SET_CURRENT, '');
      });
  },

  // used in CI for Connection Category
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setConnectionStats(
    { commit, dispatch, getters },
    { agg, dashboard = DEFAULT_DASHBOARD, date, location, metricString },
  ) {
    commit(connectionStats.types.SET_CHART_PENDING);
    commit(connectionStats.types.SET, { array: [] });
    commit(operators.types.SET, []);
    commit(locations.types.SET_CURRENT, location);

    return overview
      .getDataByLocation(location, date, metricString, dashboard, true, agg)
      .then((response) => {
        const primaryMetric = response.metrics[0].split(',')[0];

        commit(connectionStats.types.SET_CHART_SUCCESS);

        const responseOperators = Object.keys(response.operators).length > 0 ? response.operators : response.operators;
        commit(operators.types.SET, networksWithColors(Object.values(responseOperators)));

        const homeOperator = getters['charts/homeNetwork']; // { ...,  canonical_network_id: number, ...}
        dispatch('setInitialOperatorVisibility', { responseOperators, homeOperator });

        commit(connectionStats.types.SET_OVERVIEW, [...response.results]);

        commit(connectionStats.types.SET_METRIC, primaryMetric);
        // commit(metrics.types.SET_SUPPORTING, state.ciConfig.data.my)
      })
      .catch((e) => {
        captureException(e);

        commit(locations.types.SET_CURRENT, '');
      });
  },

  // used in CI for National Stats widget
  setNational(
    { commit, dispatch, getters, state }, // eslint-disable-line @typescript-eslint/no-unused-vars
    { aggregation = '90days', dashboard = DEFAULT_DASHBOARD, date, location, metric },
  ) {
    commit(nationalStats.types.SET, { array: [] });
    commit(nationalStats.types.SET_CHART_PENDING);

    return overview
      .getDataByLocation(location, date, metric, dashboard, true, aggregation)
      .then((response) => {
        commit(nationalStats.types.SET_CHART_SUCCESS);
        commit(nationalStats.types.SET, { array: response.results });

        const responseOperators = response.operators || [];
        const homeOperator = getters['charts/homeNetwork']; // { ...,  canonical_network_id: number, ...}
        dispatch('setInitialOperatorVisibility', { responseOperators, homeOperator });
      })
      .catch((e) => {
        commit(nationalStats.types.SET_CHART_FAILURE);
        captureException(e);

        commit(locations.types.SET_CURRENT, '');
      });
  },

  // used in CI for the map in Details page
  setMap(
    { commit, dispatch, getters, state }, // eslint-disable-line @typescript-eslint/no-unused-vars
    { agg, altVersion = false, countryISO3, dashboard = null, date, geocoding = 2, metric },
  ) {
    commit(shapes.types.SET, {});
    commit(mapData.types.SET_CHART_PENDING);

    return Promise.all([
      maps.getPolygons({
        country: countryISO3,
        polygon: geocoding,
        dashboard: dashboard || DEFAULT_DASHBOARD,
      }),
      maps.getData({
        country: countryISO3,
        polygon: geocoding,
        metric: metric,
        agg: agg,
        date: date || get(state, ['dashboardInfo', 'data', 'last_date_available']),
        dashboard: dashboard || DEFAULT_DASHBOARD,
        altVersion,
      }),
    ])
      .then((responses) => {
        const dataForPercentiles = responses[1].results;

        commit(
          competitive.types.SET_METRIC_BUCKETS,
          get5stepsPercentiles(dataForPercentiles).reduce((ac, point, index, self) => {
            switch (index) {
              case 0:
                ac.push([Math.max(point - 1, 0), self[index + 1]]);
                break;
              case 4:
                ac.push([point, (self[index + 1] + 0.1).toFixed(2)]);
                break;
              case 5:
                break;
              default:
                ac.push([point, self[index + 1]]);
            }

            return ac;
          }, []),
        );

        const resultsObject = {};
        responses[1].results.forEach((result) => {
          const { location } = result;
          if (resultsObject[location]) {
            resultsObject[location].push(result);
          } else {
            resultsObject[location] = [result];
          }
        });

        const features = responses[0].features.map((f) => {
          return {
            ...f,
            item: resultsObject[f.id]
              ? resultsObject[f.id].map((item) => {
                  const operator = responses[1].operators[item.canonical_network_id];
                  return {
                    value: getDatumValue(item),
                    network: `${item.canonical_network_id}`,
                    operatorColor: operator.hex_color ? `#${operator.hex_color}` : '#DEE4EC',
                    operatorInitial: operator.name_mapped[0],
                    operatorIsMvno: operator.is_mvno,
                    color: '#' + operator.hex_color,
                    label: operator.name_mapped,
                    letter: operator.name_mapped[0],
                    lci: item.lci,
                    uci: item.uci,
                    rank: item.rank,
                    comparisonRank: item.comparison && item.comparison.rank,
                    previous: item.previous,
                    comparison: item.comparison,
                  };
                })
              : [],
          };
        });
        const data = {
          ...responses[0],
          features,
        };
        commit(shapes.types.SET_KEY, {
          key: 'geoJson',
          data: Object.freeze(data),
        });

        const routeParams = router.currentRoute.value.query;

        const mapActualData = responses[1].results.sort((a, b) => b.mean - a.mean);

        commit(mapData.types.SET, {
          array: mapActualData.map(Object.freeze),
        });

        const resp = routeParams.location !== routeParams.countryid && responses[3] ? responses[3] : responses[1];
        const newOperators = networksWithColors(Object.values(resp.operators));
        dispatch('setCiOperators', { newOperators, countryISO3 });

        commit(mapData.types.SET_CHART_SUCCESS);
      })
      .catch((e) => {
        captureException(e);
        commit(mapData.types.SET_CHART_FAILURE);
      });
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setCiOperators({ commit, getters, rootGetters, state }, { countryISO3, newOperators }) {
    const operatorIds = getters.operators.map((operator) => operator.canonical_network_id);
    // Don't override operators set by setCiTrend unless they're from a different country
    const currentCountry = OPERATORS_COUNTRY_OVERRIDES[countryISO3] || [countryISO3];
    commit(operators.types.SET, [
      ...getters.operators.filter(
        (operator) => currentCountry.includes(operator.country_iso3) && operator.canonical_network_id !== -1,
      ),
      ...newOperators.filter(
        (operator) => !operatorIds.includes(operator.canonical_network_id) || operator.canonical_network_id === -1,
      ),
    ]);
  },

  // move to user store
  setUser({ commit, dispatch }) {
    commit(types.AUTH_LOAD_USER_ASYNC_PENDING);
    return config
      .getUser()
      .then((res) => {
        commit(types.AUTH_LOAD_USER_ASYNC_SUCCESS, res);
        if (res) {
          mp.identify(res.id);
          mp.people.set({
            $email: auth0.user.value.email,
            $name: auth0.user.value.name,
            $first_name: auth0.user.value.given_name,
            $last_name: auth0.user.value.family_name,
            organization: res.organization,
          });
          Sentry.setUser(res);
          dispatch('trackRoute', 'user');
          commit(user.types.SET, res);
        }

        return res;
      })
      .catch((e) => {
        captureException(e);

        commit(types.AUTH_LOAD_USER_ASYNC_FAILURE, e);
      });
  },

  // move to user store
  // merge ciConfig to user
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setUserSettings({ commit, dispatch }, mode) {
    return Promise.all([config.getMetrics(mode), config.getLocations(mode), config.getDashboardInfo(mode)])
      .then((config) => {
        const dashboardInfoData = config[2].data;

        const countryReferences = {};
        // Add regions and cities to countries so their availability can be checked before navigation
        // At the time of this writing, this is used to determine whether to show regions or national tab by default in Spotlight Details
        config[1].forEach((location) => {
          if (!['opensignal_countries', 'opensignal_regions', 'opensignal_cities'].includes(location.granularity)) {
            return;
          }

          if (location.granularity === 'opensignal_countries') {
            countryReferences[location.iso3] = location;
            return;
          }

          if (countryReferences[location.iso3]) {
            countryReferences[location.iso3][location.granularity] =
              countryReferences[location.iso3][location.granularity] || [];
            countryReferences[location.iso3][location.granularity].push(location);
          }
        });

        commit(metrics.types.SET, { array: config[0], identifier: 'key' });
        commit(locations.types.SET, { array: config[1], identifier: 'key' });
        commit(dashboardInfo.types.SET, dashboardInfoData);
        commit(
          polygons.types.SET,
          dashboardInfoData.geoconfigs_available.map((g) => {
            switch (g.client) {
              case 'opensignal':
                return {
                  id: `${g.id}`,
                  key: g.client + '_' + g.granularity,
                  name: capitalize(g.granularity),
                  prefer_points: g.prefer_points,
                };
              case 'tmobileusa':
                return {
                  id: `${g.id}`,
                  key: g.client + '_' + g.granularity,
                  name: `T-mobile ${capitalize(g.granularity)}`,
                  prefer_points: g.prefer_points,
                };
              case 'verizonusa': {
                const granularity = g.granularity
                  .split('-')
                  .map((s) => capitalize(s))
                  .join(' ');
                return {
                  id: `${g.id}`,
                  key: g.client + '_' + g.granularity,
                  name: `Verizon ${capitalize(granularity)}`,
                  prefer_points: g.prefer_points,
                };
              }
              default:
                return {
                  id: `${g.id}`,
                  key: g.client + '_' + g.granularity,
                  name: `${capitalize(g.client)} ${capitalize(g.granularity)}`,
                  prefer_points: g.prefer_points,
                };
            }
          }),
        );
        commit(ciConfig.types.SET_KEY, { key: 'country', data: config[1][0].iso3 });
        commit(ciConfig.types.SET_KEY, { key: 'location', data: config[1][0].key });
        commit(ciConfig.types.SET_KEY, { key: 'date', data: dashboardInfoData.last_date_available });
        commit(ciConfig.types.SET_KEY, {
          key: 'geocoding',
          data: `${dashboardInfoData.geoconfigs_available.find((g) => g.granularity !== 'countries').id}`,
        });
        commit(
          competitive.types.SET_SHOW_REGIONS_CITIES,
          dashboardInfoData.geoconfigs_available.find((g) => g.id === 2) &&
            dashboardInfoData.geoconfigs_available.find((g) => g.id === 3),
        );
      })
      .catch((e) => {
        captureException(e);
      });
  },

  // everywhere
  setAsCurrentLocation({ commit }, location) {
    commit(locations.types.SET_CURRENT, location);
  },

  navigateToDashboard({ dispatch, getters }, { mode }) {
    return dispatch('setUserSettings', mode)
      .then(() => {
        /** a location id string, like "277" for Manitoba or "32" for Canada */
        const getLocationParameter = () => {
          const currentGeoLocation = getters['location/currentLocation']; // {"key":"277","name":"Manitoba","granularity":"opensignal_regions","granularityId":"2","iso3":"CAN","parent_id":"null","population_size":"null"}
          if (currentGeoLocation?.key) {
            return currentGeoLocation.key;
          }

          const defaultCountry = get(getters.dashboardInfo, 'default_country'); // "32"
          if (defaultCountry && parseInt(defaultCountry, 10) > 0) {
            return defaultCountry;
          }

          const countriesVisibleFull = get(getters.dashboardInfo, 'countries_visible_full'); // [{"id": 7,"name":"Argentina","iso3":"ARG","parent_id":null,"geocoding_config":{"id":1,"granularity":"countries", "client":"opensignal","prefer_points":false},"population_size":null}, ...]
          if (countriesVisibleFull && countriesVisibleFull.length > 0) {
            return `${countriesVisibleFull[0].id}`;
          }
        };
        const locationValue = getLocationParameter();

        /** String date */
        const getEndDate = () => {
          const currentRoute = router.currentRoute.value;
          return currentRoute.query.endDate || getters.dashboardInfo.last_date_available;
        };
        const endDate = getEndDate();

        /** string[] */
        const getSelectedOperatorIDs = () => {
          const isSpotlight = ROUTE_GROUPS.Spotlight.includes(router.currentRoute.value.name);
          if (isSpotlight) {
            return (getters['charts/ci_networks'] || [])
              .filter((operator) => operator.selected)
              .map((operator) => `${operator.canonical_network_id}`);
          } else {
            return router.currentRoute.value.query.operators || []; // 360 and focus
          }
        };
        const currentSelectedOperatorIDs = getSelectedOperatorIDs();

        let destinationName; // r/Focus or r/ThreeSixty or r/CompetitiveOverview, @see ROUTES
        const query = {};

        switch (mode) {
          case 'focus':
            destinationName = ROUTES.Focus;
            query.location = locationValue;
            if (currentSelectedOperatorIDs.length > 0) {
              query.operators = currentSelectedOperatorIDs;
            }

            query.endDate = endDate;
            break;
          case 'onx360':
            destinationName = ROUTES.ThreeSixty;
            query.location = locationValue;
            if (currentSelectedOperatorIDs.length > 0) {
              query.operators = currentSelectedOperatorIDs;
            }
            break;

          // No direct way to preserve operators from the other applications, but the landing page
          // doesn't have operators anyways
          case 'spotlight':
          case 'competitive':
            destinationName = ROUTES.CompetitiveOverview;
            query.endDate = endDate;
            query.compareTo = '90days';
            query.location = locationValue;
            break;
          default:
            console.warn('dashboard handler not defined');
            return;
        }

        router.push({
          name: destinationName,
          query,
        });
      })
      .catch((e) => captureException(e));
  },

  setDisclaimerTimestamp() {
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.OS_DISCLAIMER, new Date().getTime());
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  checkDisclaimer({ dispatch }) {
    const gracePeriod = 3 * 30 * 24 * 60 * 60 * 1000; // 3 months
    const lastTimestamp = window.localStorage.getItem(LOCAL_STORAGE_KEYS.OS_DISCLAIMER);

    if (!lastTimestamp || (lastTimestamp && new Date().getTime() - lastTimestamp >= gracePeriod)) {
      document.getElementById('disclaimer').style.display = 'block';
    }
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getUserGuide({ dispatch }, url) {
    return config.getUserGuide(url);
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setUserDefaultMetricType({ dispatch }, type) {
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.DEFAULT_METRIC_TYPE, type);
  },
});

const TOKEN = window.localStorage.getItem(LOCAL_STORAGE_KEYS.OS_TOKEN);

export default StoreActionsConstructor({
  config: new Config(TOKEN),
  trends: new Trends(TOKEN),
  overview: new Overview(TOKEN),
  maps: new Maps(TOKEN),
});
