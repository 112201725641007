import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import type { SelectableMetrics } from '@/types/metrics-selector/SelectableMetrics';
import type { Dashboards } from '@/constants/dashboards';
import type { AvailableConnectionCategory } from '@/constants/allPossibleConnectionCategories';

import useLocations from '@/composables/useLocations';
import usePossibleConnectionCategories from '@/connection-categories-provider/usePossibleConnectionCategories';
import { defaultCategoryValue } from '@/constants/allPossibleConnectionCategories';

type FilterTypes = {
  location: number | string;
  metricSubtype: SelectableMetrics;
  endDate: string;
  operators: string[];
  aggregation: string;
  chartConnectionCategories: string[];
  mapConnectionCategory: string;
  mapOperator: string;
  showConfidenceIntervals: string;
};

const useFilters = (dashboard: Dashboards) => {
  const route = useRoute();
  const router = useRouter();
  // TODO: useFilters should only return values as seen in the URL. There are other composables in charge of making sure,
  // that defaults are selected correctly if no value is present in the URL (e.g useLocations, useGeocoding)
  const { locationId } = useLocations(dashboard);
  const allPossibleConnectionCategories = usePossibleConnectionCategories();

  const metricSubtype = computed(() => {
    return route.query.metricSubtype as SelectableMetrics | null;
  });

  const endDate = computed(() => {
    return route.query.endDate as string | null;
  });

  const operators = computed(() => {
    if (!route.query.operators) {
      return [] as string[];
    } else if (typeof route.query.operators === 'string') {
      return [route.query.operators] as string[];
    } else {
      return route.query.operators as string[];
    }
  });

  const aggregation = computed(() => {
    if (!route.query.aggregation) {
      return '90days';
    }

    return route.query.aggregation as string;
  });

  const showConfidenceIntervals = computed(() => {
    if (!route.query.showConfidenceIntervals) {
      return true;
    }

    return route.query.showConfidenceIntervals === 'true';
  });

  const chartConnectionCategories = computed(() => {
    if (!route.query.chartConnectionCategories) {
      return [] as string[];
    } else if (typeof route.query.chartConnectionCategories === 'string') {
      return [route.query.chartConnectionCategories] as string[];
    } else {
      return route.query.chartConnectionCategories as string[];
    }
  });

  const mapConnectionCategory = computed(() => {
    if (!route.query.mapConnectionCategory) {
      return (
        allPossibleConnectionCategories.find(
          (category) => category.categoryValue === defaultCategoryValue,
        ) as AvailableConnectionCategory
      ).categoryValue;
    }

    return route.query.mapConnectionCategory as string;
  });

  const mapOperator = computed(() => {
    return route.query.mapOperator as string;
  });

  const setFilters = (filters: Partial<FilterTypes>) => {
    return router.push({
      query: {
        ...route.query,
        ...filters,
      },
    });
  };

  const removeFilter = (filter: string) => {
    const query = { ...route.query };
    delete query[filter];

    router.push({ ...query });
  };

  return {
    setFilters,
    removeFilter,
    locationId,
    metricSubtype,
    endDate,
    operators,
    aggregation,
    chartConnectionCategories,
    mapConnectionCategory,
    mapOperator,
    showConfidenceIntervals,
  };
};

export default useFilters;
