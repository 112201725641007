import { computed } from 'vue';

import ROUTES from '@/constants/routes';

import useDashboardInfo from '@/composables/useDashboardInfo';
import { Dashboards } from '@/constants/dashboards';

const useCoreHealthTabAvailability = () => {
  const { data: dashboardInfoResponse } = useDashboardInfo(Dashboards.Focus);
  const coreHealthSelectableMetricTabs = computed(() => {
    let selectableTabNames: [string, string, string][] = [
      [ROUTES.FocusCoreHealthByCDN, 'by_cdn', 'By CDN'],
      [ROUTES.FocusCoreHealthByOperatorCDN, 'by_operator_cdn', 'By Operator CDN'],
    ];

    const allowedCoreHealthTabs = dashboardInfoResponse.value?.data.allowed_tabs?.core_health;

    // filter selectable tabs based on allowed tabs
    if (allowedCoreHealthTabs) {
      selectableTabNames = selectableTabNames.filter(([, page]) => {
        return allowedCoreHealthTabs.includes(page as (typeof allowedCoreHealthTabs)[number]);
      });
    }

    return selectableTabNames;
  });

  const firstAvailableTabForCoreHealth = computed(() => {
    return coreHealthSelectableMetricTabs.value?.[0]?.[0];
  });

  return { coreHealthSelectableMetricTabs, firstAvailableTabForCoreHealth };
};

export default useCoreHealthTabAvailability;
