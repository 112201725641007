import { computed, ref, watch, Ref } from 'vue';

import axios, { AxiosError } from 'axios';

/**
 * When the swyft db is scaling up, the backend will return a 503 error
 *  with `grace_period` variable in a json payload, e.g. `{...., grace_period: 10000, .....}`
 */

type DbScalingUpError = AxiosError<{
  grace_period: number;
}>;

export const isDbScalingUpError = (error: Error | null) => {
  if (axios.isAxiosError(error) && error?.response?.status == 503 && error?.response?.data?.grace_period > 0) {
    return true;
  }
  return false;
};

export const useDbScalingSetup = () => {
  const lastDbScalingFailureCount = ref(0);

  const getDbScalingRetry = (failureCount: number, error: Error) => {
    if (isDbScalingUpError(error)) {
      return true;
    }

    return Math.max(failureCount - lastDbScalingFailureCount.value, 0) < 1;
  };

  const getDbScalingRetryDelay = (retryAttempt: number, error: Error) => {
    if (isDbScalingUpError(error)) {
      const dbScalingError = error as DbScalingUpError;
      const gracePeriod = dbScalingError.response?.data?.grace_period;
      if (gracePeriod) {
        return gracePeriod;
      }
    }

    const actualFailureCount = Math.max(retryAttempt - lastDbScalingFailureCount.value, 0);

    return Math.min(1000 * 2 ** actualFailureCount, 30000);
  };

  return { lastDbScalingFailureCount, getDbScalingRetry, getDbScalingRetryDelay };
};

export const useDbScaling = (options: {
  failureCount: Ref<number>;
  failureReason: Ref<Error | null>;
  lastDbScalingFailureCount: Ref<number>;
}) => {
  const { failureCount, failureReason, lastDbScalingFailureCount } = options;
  const isDbScalingUp = computed(() => {
    return isDbScalingUpError(failureReason?.value);
  });

  watch([failureCount, failureReason], ([failureCount, failureReason]) => {
    // lastDbScalingFailureCount denotes the last failureCount at which a Db Scaling occurs

    if (isDbScalingUpError(failureReason)) {
      lastDbScalingFailureCount.value = failureCount;
      return;
    }
    if (failureCount == 0) {
      lastDbScalingFailureCount.value = 0;
      return;
    }
  });

  return { isDbScalingUp };
};
