<script setup lang="ts">
import { computed } from 'vue';
import { useMapConnectionCategory } from './useMapConnectionCategory';
import OnxRadioButtonListDropdown from '@/components/onx/OnxRadioButtonListDropdown.vue';
import { Dashboards } from '@/constants/dashboards';

const { mapConnectionCategories, onMapConnectionCategoryChange, selectedMapConnectionCategory } =
  useMapConnectionCategory(Dashboards.Focus);

const items = computed(() => {
  return mapConnectionCategories.value.map((category) => ({
    keyAccessor: category.categoryValue,
    inset: category.subCategory,
    disabled: category.disabled,
    label: category.categoryLabel,
    value: category,
    checked: category.categoryValue === selectedMapConnectionCategory.value?.categoryValue,
  }));
});
</script>

<template>
  <OnxRadioButtonListDropdown
    label="Connection Category:"
    :selected-label="selectedMapConnectionCategory?.categoryLabel"
    list-container-class="onx-main-connection-category__list"
    :items="items"
    @list-item-click="onMapConnectionCategoryChange"
  />
</template>

<style lang="scss">
.onx-main-connection-category__list {
  .onx-list__item--disabled {
    .main {
      opacity: 1 !important;
    }
  }
}
</style>
