import { METRIC_TYPE_OPTIONS, METRIC_TYPES } from '@/constants/constants';

import type { MetricDescriptor } from '@/types/MetricDescriptor';
import type { AvailableConnectionCategory } from '@/constants/allPossibleConnectionCategories';

const getSpotlightOverviewConnectionCategories = (metrics: MetricDescriptor[], userGroup: string) => {
  const isConnectionCategoryAvailable = (connectionCategory: string) => {
    return metrics.some((m) => m.type === connectionCategory && m.subcategory === userGroup);
  };

  return METRIC_TYPE_OPTIONS.filter(
    (c) => isConnectionCategoryAvailable(c.value) && c.value !== METRIC_TYPES.FourGFiveG,
  ).map<AvailableConnectionCategory>((c) => {
    return {
      categoryLabel: c.label,
      categoryValue: c.value,
      subCategory: false,
    };
  });
};

export default getSpotlightOverviewConnectionCategories;
