import type { Dashboards } from '@/constants/dashboards';
import { MetricSubtypes } from '@/types/MetricSubtypes';
import useDashboardInfo from '@/composables/useDashboardInfo';
import useCheckIfMetricSubtypeOrDatasetExists from '@/composables/useCheckIfMetricSubtypeOrDatasetExists';

export const OnxPermissionTopics = {
  myDashboards: 'myDashboards',
  geohash: 'geohash',
  RANHealth: 'RANHealth',
  RANHealthCoverage: 'RANHealthCoverage',
  RANHealthNetworkRollout: 'RANHealthNetworkRollout',
  RANHealthDeployedSpectrum: 'RANHealthDeployedSpectrum',
  RANHealthSiteMapper: 'RANHealthSiteMapper',
  CoreHealth: 'CoreHealth',
  SpotlightPulse: 'SpotlightPulse',
  IntlSpotlight: 'IntlSpotlight',
} as const;

export type OnxPermissionTopics = (typeof OnxPermissionTopics)[keyof typeof OnxPermissionTopics];

export const useOnxLicencePermission = (dashboard: Dashboards, onxPermissionTopic: OnxPermissionTopics) => {
  const { data } = useDashboardInfo(dashboard);
  const { checkIfMetricSubtypeOrDatasetExists } = useCheckIfMetricSubtypeOrDatasetExists();

  const onx_licence_permission = data.value?.data.onx_licence_permission;
  const allowedTabs = data.value?.data.allowed_tabs;

  const hasCoverage =
    allowedTabs?.ran_health?.includes('coverage') &&
    checkIfMetricSubtypeOrDatasetExists(MetricSubtypes.CoverageGeohash7CountSubcategory);
  const hasNetworkRollout = allowedTabs?.ran_health?.includes('network_rollout');
  const hasDeployedSpectrum = allowedTabs?.ran_health?.includes('deployed_spectrum');
  const hasSiteMapper = allowedTabs?.ran_health?.includes('site_mapper');

  const hasCoreHealth = allowedTabs?.core_health?.length > 0;

  const predicateFunctionLookup = {
    [OnxPermissionTopics.myDashboards]: onx_licence_permission?.my_dashboard_enabled,
    [OnxPermissionTopics.geohash]: onx_licence_permission?.geohash_enabled,
    [OnxPermissionTopics.RANHealth]: hasCoverage || hasNetworkRollout || hasDeployedSpectrum || hasSiteMapper,
    [OnxPermissionTopics.RANHealthCoverage]: hasCoverage,
    [OnxPermissionTopics.RANHealthNetworkRollout]: hasNetworkRollout,
    [OnxPermissionTopics.RANHealthDeployedSpectrum]: hasDeployedSpectrum,
    [OnxPermissionTopics.RANHealthSiteMapper]: hasSiteMapper,
    [OnxPermissionTopics.SpotlightPulse]: data.value?.data.include_spotlight_pulse,
    [OnxPermissionTopics.IntlSpotlight]: data.value?.data.include_international_spotlight,
    [OnxPermissionTopics.CoreHealth]: hasCoreHealth,
  };

  return predicateFunctionLookup[onxPermissionTopic] ?? false;
};
