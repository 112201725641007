import { computed, unref, MaybeRef } from 'vue';

import usePolygons from '@/composables/usePolygons';
import usePolygonByLocationId from '@/composables/usePolygonByLocationId';
import { Dashboards } from '@/constants/dashboards';
import { OS_GEOCODINGS } from '@/constants/constants';
import useFilters from '@/composables/useFilters';
import useGeocoding from '@/composables/useGeocoding';
import useLocations from '@/composables/useLocations';
import useMapSizeAndBounds from '@/composables/useMapSizeAndBounds';
import useHeavyDbPolygons from '@/composables/useHeavyDbPolygons';
import useGeohashesQueryParam from '@/focus/composables/query-params/useGeohashesQueryParam';
import { useOnxLicencePermission, OnxPermissionTopics } from '@/composables/useOnxLicencePermission';
import useGeocodingRestrictions from '@/focus/composables/useGeocodingRestrictions';

type Options = {
  enableGeohashes?: MaybeRef<boolean>;
};

const useMapWithPolygons = (dashboard: Dashboards, options: Options = {}) => {
  const { enableGeohashes = false } = options;
  const geohashAllowed = useOnxLicencePermission(dashboard, OnxPermissionTopics.geohash);
  const {
    bbox: mapBbox,
    handleNewBounds,
    latMax,
    latMin,
    lngMax,
    lngMin,
    mapSizeAndBoundsReady,
    setBounds,
    ...mapRest
  } = useMapSizeAndBounds();
  const { locationId, setFilters } = useFilters(dashboard);
  const { actualGeocoding, nextOSGeocoding } = useGeocoding(dashboard);
  const { countryIso3 } = useLocations(dashboard);
  const { geohashes } = useGeohashesQueryParam();
  const geocodingRestrictions = useGeocodingRestrictions();

  const computedLocationId = computed({
    get: () => locationId.value,
    set: (value) => {
      setFilters({ location: value });
    },
  });

  const { polygonByLocationId } = usePolygonByLocationId(dashboard, {
    geocoding: actualGeocoding,
    countryIso3,
    locationId: computedLocationId,
  });

  const { data: polygons, isLoading: polygonsLoading } = usePolygons(dashboard, {
    geocoding: nextOSGeocoding,
    countryIso3,
  });

  const {
    isDbScalingUp: heavyDbPolygonsIsDbScalingUp,
    query: { data: heavyDbPolygonsResponse, isLoading: heavyDbPolygonsLoading },
  } = useHeavyDbPolygons(dashboard, {
    location: computedLocationId,
    enabled: enableGeohashes,
  });

  const computedPolygonData = computed(() => {
    // heavydb / geohashes branch
    if (actualGeocoding.value === 3 && unref(enableGeohashes)) {
      if (!heavyDbPolygonsResponse.value || !polygonByLocationId.value) {
        return null;
      }

      const { features } = heavyDbPolygonsResponse.value.data.features;

      if (!geohashAllowed) {
        return { features: [], bbox: polygonByLocationId.value.bbox };
      }

      return {
        features: features.map((f) => ({
          ...f,
          properties: {
            ...f.properties,
            geohash: true,
            selected: geohashes.value.includes(f.properties.id),
          },
        })),
        bbox: polygonByLocationId.value.bbox,
      };
    }

    // metricsdb / countries, regions, cities or custom polygons branch
    if (!polygons.value || !polygonByLocationId.value) {
      return null;
    }

    const bbox =
      actualGeocoding.value === OS_GEOCODINGS.countries ? polygons.value.data.bbox : polygonByLocationId.value.bbox;

    if (polygonByLocationId.value && geocodingRestrictions.includes(nextOSGeocoding.value)) {
      return {
        features: [],
        bbox,
      };
    }

    const features = polygons.value.data.features;

    return {
      features: mapSizeAndBoundsReady.value ? features.map((f) => ({ ...f, location: f.id })) : [],
      bbox,
    };
  });

  const mapIsLoading = computed(() => {
    if (geocodingRestrictions.includes(nextOSGeocoding.value)) {
      return false;
    }

    return (unref(enableGeohashes) && heavyDbPolygonsLoading.value) || polygonsLoading.value;
  });

  const isDbScalingUp = computed(() => {
    if (actualGeocoding.value === 3 && unref(enableGeohashes)) {
      return heavyDbPolygonsIsDbScalingUp.value;
    }
    return false;
  });

  return {
    computedPolygonData,
    mapIsLoading,
    handleNewBounds,
    computedLocationId,
    latMin,
    latMax,
    lngMin,
    lngMax,
    mapSizeAndBoundsReady,
    setBounds,
    bbox: mapBbox,
    isDbScalingUp,
    ...mapRest,
  };
};

export default useMapWithPolygons;
