import get from 'lodash/get';
import {
  METRIC_HERO_LIST,
  METRIC_CHART_INFO,
  METRIC_GROUP_LABEL,
  METRIC_GROUP_ALTERNATE_LABEL,
  METRIC_GROUP_ABR_LABEL,
  METRIC_GROUP_ABR_ALTERNATE_LABEL,
  CHART_MODIFIERS,
  FIVEG_METRIC_SUBTYPES,
  METRIC_CDNS,
  METRIC_TYPES,
  DEFAULT_USER_GROUP,
  LOCAL_STORAGE_KEYS,
} from '@/constants/constants';

function getMetricAndSufix(hero) {
  return hero ? hero.split('_') : [];
}

function isHero(metricString) {
  const [metric] = getMetricAndSufix(metricString);
  return METRIC_HERO_LIST.includes(metric);
}

function toMainMetric(metric) {
  if (!metric) {
    return metric;
  }

  if (metric.includes('5gmmwave')) {
    return metric;
  }

  return metric.replace(/5g(.)*$/, '5g');
}

function getHeroOfChart(metricString) {
  const [metric, sufix] = getMetricAndSufix(metricString);
  if (METRIC_CHART_INFO[metric]) {
    let heroSufix = METRIC_CHART_INFO[metric].heroSufix ? METRIC_CHART_INFO[metric].heroSufix : sufix;

    if (FIVEG_METRIC_SUBTYPES.includes(heroSufix)) {
      heroSufix = '5g';
    }

    return `${METRIC_CHART_INFO[metric].hero}_${heroSufix}`;
  } else {
    return 'no_metric';
  }
}

function getChartOfHero(metricString) {
  const [metric, sufix] = getMetricAndSufix(metricString);
  const chartMetric = Object.keys(METRIC_CHART_INFO).find(
    (key) =>
      (METRIC_CHART_INFO[key].hero === metric &&
        (METRIC_CHART_INFO[key].parent === metric || METRIC_CHART_INFO[key].parent === 'infrastructure')) ||
      (metric === 'gamesexperience' && key === 'cpgamesrtt'),
  );

  return `${chartMetric}_${sufix}`;
}

function getRawSiblings(metric) {
  return METRIC_CHART_INFO[getMetricAndSufix(metric)[0]].siblings;
}

function getSiblingsOfChart(metricString) {
  const [metric, sufix] = getMetricAndSufix(metricString);
  const { parent, siblings } = METRIC_CHART_INFO[metric];
  const hero = parent.includes('videoabr') ? parent : METRIC_CHART_INFO[metric].hero;

  return siblings.map((i) => {
    switch (i) {
      case 'trend':
        return `${parent}_${sufix}`;
      case 'dp':
      case 'hourly':
      case 'cp':
      case 'cpip':
      case 'cdn':
      case 'ip':
      case 'cdnresip':
      case 'cdnres':
      case 'binned':
      case 'binnedcdn':
      case 'binnedcdnres':
      case 'consistency':
      case 'peak':
        return `${i}${parent}_${sufix}`;
      case 'throughput':
        return `binnedvideo${i}_${sufix}`;
      case 'consumed':
        return `binnedvideodata${i}_${sufix}`;
      case 'enodeb':
      case 'cell':
      case 'enodebcellcountdist':
      case 'cellbanddist':
      case 'categorydownload':
      case 'categorypeakdownload':
      case 'categoryupload':
      case 'categorypeakupload':
      case 'categorylatency':
      case 'categorypeaklatency':
      case 'hourlydownloadstallingoccurrence':
      case 'downloadstallingoccurrence':
      case 'voicertt':
      case 'voicepacketloss':
      case 'gamesrtt':
      case 'gamespacketloss':
        return `${i}_${sufix}`;
      case 'hourlypoorsignalquality':
      case 'poorsignalquality':
      case 'hourlypoorsignallevel':
      case 'poorsignallevel':
        return `${i}_lte`;
      case 'frdownload':
      case 'frupload':
        return `${i}_5g`;
      case 'mmdownload':
      case 'mmpeakdownload':
      case 'mmupload':
      case 'mmpeakupload':
        if (parent.includes('ios')) return `iosmodel${i.substring(2)}_${sufix}`;
        return `androidmodel${i.substring(2)}_${sufix}`;
      case 'cpops':
        return `cp${parent}_${sufix}`;
      case 'cdnops':
        return `cdn${hero}_${sufix}`;
      case 'cdnresops':
        return `cdnres${parent}_${sufix}`;
      default:
        return `${i}${hero}_${sufix}`;
    }
  });
}

function getParentOfChart(metricString) {
  const [metric, sufix] = getMetricAndSufix(metricString);
  const metricConfig = METRIC_CHART_INFO[metric] || {};
  if (!metricConfig.parent) {
    return `${metric}_${sufix}`;
  }
  return `${metricConfig.parent}_${sufix}`;
}

function isChartInMenu(metricString) {
  return METRIC_CHART_INFO[getMetricAndSufix(metricString)[0]].menu;
}

function isHeroLabelsFromChartMetric(metricString) {
  // This is a flag to decide if the hero labels of the page
  // should come from chart metric or hero metric
  const [metric, _] = getMetricAndSufix(metricString);
  return (METRIC_CHART_INFO[metric] || {}).isHeroLabelsFromChartMetric || false;
}

function findMetricGroupForRouteName(metric) {
  if (metric.includes('category')) return 'category';
  if (metric.includes('model')) return 'model';
  if (metric.includes('enodebcellcountdist')) return 'enodebcellcountdist';
  if (metric.includes('enodeb')) return 'enodeb';
  if (metric.includes('cellbanddist')) return 'cellbanddist';
  if (metric.includes('cell')) return 'cell';
  if (metric.includes('consumed')) return 'consumed';
  if (metric.includes('binnedcdn')) return 'binnedcdn';
  if (metric.includes('binnedcdnres')) return 'binnedcdnres';
  if (metric.includes('cdnresip')) return 'cdnresip';
  if (metric.includes('cdnres')) return 'cdnres';
  if (metric.includes('cdn')) return 'cdn';
  if (metric.includes('cpip')) return 'cpip';
  if (metric.includes('cp')) return 'cp';
  if (metric.includes('binned')) return 'binned';
  if (metric.includes('dp')) return 'hourly';
  if (metric.includes('hourly')) return 'hourly';
  if (metric.includes('peak')) return 'peak';
  if (metric.includes('consistency')) return 'consistency';
  if (metric.includes('ip')) return 'ip';
  if (metric.includes('frdownload')) return 'ranges';
  if (metric.includes('frupload')) return 'ranges';

  return 'trend';
}

function findAlternateMetricGroupForRouteName(metric) {
  if (metric.includes('cdnres')) return 'cdnresops';
  if (metric.includes('cp')) return 'cpops';
  if (metric.includes('cdn')) return 'cdnops';
}

function findMetricTypeForTabLabel(metric) {
  const specialMetrics = [
    // unique labels
    'hourlypoorsignalquality',
    'poorsignalquality',
    'hourlypoorsignallevel',
    'poorsignallevel',
    'hourlydownloadstallingoccurrence',
    'downloadstallingoccurrence',
    'categorydownload',
    'categorypeakdownload',
    'categoryupload',
    'categorypeakupload',
    'categorylatency',
    'categorypeaklatency',
    'enodebcellcountdist',
    'enodeb',
    'cellbanddist',
    'cell',
    'voicertt',
    'voicepacketloss',
    // 'gamesrtt',
    // 'gamespacketloss',

    // pattern based labels
    'consumed',
    'binnedcdn',
    'binnedcdnres',
    'cpops',
    'cpip',
    'cdnresip',
    'ip',
    'cdnops',
    'cdnres',
    'cdn',
    'cp',
    'binned',
    'dp',
    'hourly',
    'consistency',
  ];

  const special = specialMetrics.find((m) => metric.includes(m));

  if (special) return special;

  if (metric.includes('modeldownload')) return 'mmdownload';
  if (metric.includes('modelpeakdownload')) return 'mmpeakdownload';
  if (metric.includes('modelupload')) return 'mmupload';
  if (metric.includes('modelpeakupload')) return 'mmpeakupload';

  if (metric.includes('peakdownload')) return 'peakspeed';
  if (metric.includes('peakupload')) return 'peakspeed';
  if (metric.includes('peaklatency')) return 'minimumlatency';
  if (metric.includes('frdownload')) return 'frdownload';
  if (metric.includes('frupload')) return 'frupload';

  return 'trend';
}

function findAlternateMetricTypeForTabLabel(metric) {
  if (metric.includes('cdnres')) return 'cdnresops';
  if (metric.includes('cp')) return 'cpops';
  if (metric.includes('cdn')) return 'cdnops';
}

// TODO refactor group labels to take duplication
// and remove function use
function getGroupLabel(metric, alternateLabel) {
  if (metric.includes('videoabr')) {
    let label;

    if (alternateLabel) {
      label = METRIC_GROUP_ABR_ALTERNATE_LABEL[findAlternateMetricTypeForTabLabel(metric)];
    } else {
      label = METRIC_GROUP_ABR_LABEL[findMetricTypeForTabLabel(metric)];
    }

    if (label) {
      return label;
    }
  }

  if (alternateLabel) {
    return METRIC_GROUP_ALTERNATE_LABEL[findAlternateMetricTypeForTabLabel(metric)];
  }

  return METRIC_GROUP_LABEL[findMetricTypeForTabLabel(metric)];
}

// change the labels of LTE to 4G
function toTypeLabel(type) {
  switch (type.toUpperCase()) {
    case 'LTE':
      return '4G';
    case '3GLTE':
      return '3G/4G';
    default:
      return type.toUpperCase();
  }
}

function getChartModifier(metric) {
  return metric && CHART_MODIFIERS[metric.split('_')[0]];
}

function getUnit(metric) {
  return get(metric, ['units', 'short'], '');
}

function getMetric(metrics, key) {
  const metric = metrics.find((m) => m.key === key);

  return metric ? metric : getMetricAlternative(metrics, key);
}

function getMetricAlternative(metrics, key) {
  if (key === 'availability') {
    key = 'availability_3glte5g';
  }

  if (key === 'extent') {
    key = 'extent_5g';
  }

  return metrics.find((m) => m.key === key) || {};
}

function getMetricCdns(metricKey) {
  const metricSubtype = metricKey.split('_')[0];
  const baseMetric = metricSubtype.replace(/^(cdn|cp|binnedcdn)/, '');
  return METRIC_CDNS[baseMetric] || [];
}

function getUserDefaultMetricType() {
  return window.localStorage.getItem(LOCAL_STORAGE_KEYS.DEFAULT_METRIC_TYPE);
}
function setUserDefaultUserGroup(type) {
  window.localStorage.setItem(LOCAL_STORAGE_KEYS.DEFAULT_USER_GROUP, type);
}
function getUserDefaultUserGroup() {
  return window.localStorage.getItem(LOCAL_STORAGE_KEYS.DEFAULT_USER_GROUP);
}

function isAwardMetric(metric) {
  if (metric.kind !== 'availability') {
    return false;
  }

  switch (metric.type) {
    case METRIC_TYPES.Overall:
      return metric.user_group === undefined || metric.user_group === DEFAULT_USER_GROUP;
    case METRIC_TYPES.FiveG:
      return metric.user_group === '5guser';
    case METRIC_TYPES.FourG:
      return metric.user_group === '4guser';
    default:
      return false;
  }
}

export {
  isHero,
  getHeroOfChart,
  getRawSiblings,
  getSiblingsOfChart,
  getParentOfChart,
  getChartOfHero,
  isChartInMenu,
  isHeroLabelsFromChartMetric,
  getGroupLabel,
  toTypeLabel,
  getChartModifier,
  getUnit,
  getMetric,
  getMetricCdns,
  findMetricGroupForRouteName,
  getUserDefaultMetricType,
  getUserDefaultUserGroup,
  setUserDefaultUserGroup,
  toMainMetric,
  isAwardMetric,
};
