<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { OnxTabsContext, OnxTabs, OnxTab } from '@/components/onx/tabs';
import { FocusHeader, useFocusHeaderTabChange } from '@/focus/focus-header';

import useCoreHealthTabAvailability from '@/focus/composables/useCoreHealthTabAvailability';

const onTabChange = useFocusHeaderTabChange();

const { coreHealthSelectableMetricTabs } = useCoreHealthTabAvailability();

const route = useRoute();
const selectedTab = computed(() => {
  return route.matched?.[2]?.name;
});
</script>

<template>
  <FocusHeader>
    <template #tabs>
      <OnxTabsContext :selected-tab="selectedTab">
        <OnxTabs @change="onTabChange">
          <OnxTab v-for="[route, , name] in coreHealthSelectableMetricTabs" :id="route" :key="route">
            {{ name }}
          </OnxTab>
        </OnxTabs>
      </OnxTabsContext>
    </template>
  </FocusHeader>
</template>
