<template>
  <tr :class="componentClass" class="RankingTableItem" @click="$emit('click')">
    <td v-if="!rankHidden" class="RankingTableItem__ranking">
      {{ rank || '&nbsp;&nbsp;' }}
    </td>
    <td :class="rankComparisonIconClass(comparisonRank)" class="RankingTableItem__rank-icon">
      <FontAwesomeIcon
        v-if="comparisonRank !== undefined && comparisonRank !== null"
        :icon="icons[rankComparisonIcon(comparisonRank)]"
        size="xs"
      />
    </td>
    <td class="RankingTableItem__OperatorAvatar">
      <OperatorAvatar
        v-if="operatorName"
        :name="operatorName"
        :background-color="operatorColor"
        :background-style="operatorIsMvno ? 'outline' : 'fill'"
      />
    </td>
    <td class="RankingTableItem__operator-name">
      {{ operatorName }}
      {{ hint ? `(${hint})` : '' }}
    </td>
    <td class="RankingTableItem__value">
      {{ value === null ? '-' : value }}
    </td>
    <td>
      <div v-if="!confidenceHidden" class="RankingTableItem__confidence">
        <div class="RankingTableItem__confidence-upper">
          {{ uci }}
        </div>
        <div class="RankingTableItem__confidence-lower">
          {{ lci }}
        </div>
      </div>
    </td>
    <template v-if="showSecondaryValue">
      <td class="RankingTableItem__value RankingTableItem__value--compare">
        {{ secondaryValue === null ? '-' : secondaryValue }}
      </td>
      <td>
        <div v-if="!confidenceHidden" class="RankingTableItem__confidence">
          <div class="RankingTableItem__confidence-upper">
            {{ compareUci }}
          </div>
          <div class="RankingTableItem__confidence-lower">
            {{ compareLci }}
          </div>
        </div>
      </td>
    </template>
  </tr>
</template>

<script>
import { faCaretDown, faCaretUp, faChartBar, faEquals } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import OperatorAvatar from '../OperatorAvatar';
import { numberToTwoDecimals } from '@/utils/filters';

export default {
  name: 'RankingTableItem',
  components: {
    OperatorAvatar,
    FontAwesomeIcon,
  },
  filters: {
    numberToTwoDecimals,
  },
  props: {
    comparisonRank: { type: Number, default: undefined },
    dimmed: { type: Boolean, default: false },
    selectable: { type: Boolean, default: false },
    operatorColor: { type: String, default: undefined },
    operatorInitial: { type: String, default: undefined },
    operatorName: { type: String, default: undefined },
    operatorIsMvno: { type: Boolean, default: undefined },
    rank: { type: Number, default: undefined },
    value: { type: [String, Number], default: undefined },
    lci: { type: Number, default: undefined },
    uci: { type: Number, default: undefined },
    showSecondaryValue: { type: Boolean, default: false },
    secondaryValue: { type: [String, Number], default: undefined },
    compareLci: { type: Number, default: undefined },
    compareUci: { type: Number, default: undefined },
    rankHidden: { type: Boolean, default: false },
    confidenceHidden: { type: Boolean, default: false },
    decimalsHidden: { type: Boolean, default: false },
    hint: { type: String, default: undefined },
  },
  data() {
    return {
      dropdownOpen: false,
      icons: { faChartBar, faCaretDown, faCaretUp, faEquals },
    };
  },
  computed: {
    componentClass() {
      const { dimmed, selectable } = this;
      return {
        'RankingTableItem--dimmed': dimmed,
        'RankingTableItem--selectable': selectable,
      };
    },
  },
  methods: {
    rankComparisonIcon(rankComparison) {
      if (rankComparison < 0) {
        return 'faCaretUp';
      } else if (rankComparison > 0) {
        return 'faCaretDown';
      } else if (rankComparison === 0) {
        return 'faEquals';
      }
    },
    rankComparisonIconClass(rankComparison) {
      if (rankComparison < 0) {
        return 'RankingTableItem__rank-icon--positive';
      } else if (rankComparison > 0) {
        return 'RankingTableItem__rank-icon--negative';
      } else {
        return 'RankingTableItem__rank-icon--neutral';
      }
    },
  },
};
</script>

<style global lang="scss">
@use 'scss/variables.module' as *;
@use 'foundation-sites/scss/foundation' as *;

.RankingTableItem {
  border-bottom: 1px solid $color-gray-border-table;
  height: 42px;
  align-items: center;

  td {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }

  &:last-child {
    border-bottom: none;
    border-radius: 0 0 $border-radius-medium $border-radius-medium;
  }

  &--dimmed {
    opacity: 0.5;
  }

  &--selectable {
    cursor: pointer;
    &:hover {
      color: $color-white;
      background-color: $color-electric-blue;
    }
  }

  &__OperatorAvatar {
    padding: 0;
  }

  &__operator-name {
    font-size: $font-size-10;
    font-weight: $font-weight-roboto-bold;
    width: 50%;
  }

  &__value {
    @include xy-cell(shrink);
    font-size: $font-size-18;
    font-weight: $font-weight-roboto-light;
    padding: 0;

    &--compare {
      border-left: 1px solid $color-gray-border-table;
      padding: 0 $table-padding;
    }
  }

  &__confidence {
    @include xy-grid(vertical);
    font-size: $font-size-10;
    font-weight: $font-weight-roboto-light;
  }

  &__confidence-upper,
  &__confidence-lower {
    @include xy-cell(shrink, $gutters: 0);
  }

  &__ranking {
    font-size: $font-size-12;
    color: $color-blue-header;
    padding-right: 0;
  }

  &__rank-icon {
    font-size: $icon-font-size-medium;
    text-align: center;
    color: $color-blue-header;
    opacity: 0;
    padding: 0 0.5em;

    &--positive {
      color: $color-positive-green;
      opacity: 1;
    }

    &--negative {
      color: $color-negative-red;
      opacity: 1;
    }
  }
}
</style>
