<script lang="ts" setup>
import { differenceInDays, format, subDays } from 'date-fns';
import { computed, ref, toRefs, unref } from 'vue';
import { useStore } from 'vuex';
import useMetrics from '@/components/onx/composables/useMetrics';
import useSpotlightQueryParams from '@/components/specialized/useSpotlightQueryParams';
import ChartWrapperExtended from '@/components/visual/ChartWrapperExtended.vue';
import LineChart from '@/components/visual/chart/LineChart.vue';
import useCurrentDashboardName from '@/composables/useCurrentDashboardName';
import useDashboardInfo from '@/composables/useDashboardInfo';
import useGeocodingConfig from '@/composables/useGeocodingConfig';
import { AGGREGATIONS, METRIC_TYPE_NAMES, OS_GEOCODINGS, USER_GROUPS } from '@/constants/constants';
import { API_LONG_DATE_FORMAT } from '@/constants/dateFormats';
import { MetricDescriptor } from '@/types/MetricDescriptor';
import type { MetricStructuresEnum } from '@/types/MetricStructures';
import { MetricTypeNamesIndex } from '@/types/MetricTypeNamesIndex';
import { Operator } from '@/types/Operator';
import { getSafeDate } from '@/utils/date';
import { fixHexColor } from '@/utils/helpers';
import { getDatumValue } from '@/utils/viewHelpers';

type Props = {
  aggregation: string;
  endDate: Date;
  metric: string;
  userGroup?: string;
};

const props = defineProps<Props>();
const { aggregation, endDate, metric, userGroup } = toRefs(props);

const { metricsByIdentifier } = useMetrics();
const dashboard = useCurrentDashboardName();
const dashboardInfo = useDashboardInfo(dashboard.value);

const store = useStore();

const timeRange = computed(() => {
  return store.getters['competitive/timeRange'] as
    | {
        end: Date;
        start: Date;
      }
    | {
        end: null;
        start: null;
      };
});

const nbDays = computed(() => {
  const timeframe = store.getters['competitive/timeframeDays'] as string | null; // either "30", "90", ... or null
  const _timeRange = unref(timeRange);

  if (timeframe !== null) {
    return parseInt(timeframe);
  } else if (_timeRange.end && _timeRange.start) {
    return differenceInDays(_timeRange.end, _timeRange.start);
  } else {
    return 180; // I dunno if this is a realistic default
  }
});

const geoconfigEndDate = computed(() => {
  if (timeRange.value && timeRange.value.end) {
    return timeRange.value.end;
  } else {
    return undefined;
  }
});

const { query: geocodingConfig } = useGeocodingConfig<MetricStructuresEnum.RankedSimple>(dashboard.value, {
  aggregation,
  metric,
  endDate: geoconfigEndDate,
  operatorInfo: true,
  enabled: ref(true),
  nbDays: nbDays,
  geocoding: ref(OS_GEOCODINGS.countries),
});

type LineChartDatum = {
  x: string;
  date: string;
  y: number;
  lci: number;
  uci: number;
  rank?: number;
};

type DataSeries = {
  label: string;
  color: string;
  backgroundColor: string;
  operator: Operator;
  data: LineChartDatum[];
};

const trendSeries = computed<Record<string, DataSeries>>(() => {
  if (!geocodingConfig.data.value?.data) {
    return {};
  }

  const data = geocodingConfig.data.value.data;
  const _trendSeries: Record<string, DataSeries> = data.results.reduce(
    // network ID => DataSeries
    (accumulator, currentDatum) => {
      const networkIdString = `${currentDatum.canonical_network_id}`;
      const formattedDatum: LineChartDatum = {
        x: currentDatum.date,
        date: currentDatum.date,
        y: getDatumValue(currentDatum),
        lci: currentDatum.lci,
        uci: currentDatum.uci,
        rank: currentDatum.rank,
      };

      if (accumulator.hasOwnProperty(networkIdString)) {
        // if we need to add this to an existing series
        accumulator[networkIdString].data.push(formattedDatum);
      } else {
        // or start a new series for this operator, by network id
        const operator = (data.operators as Record<number, Operator>)[currentDatum.canonical_network_id];
        if (operator) {
          const color = fixHexColor(operator.hex_color);
          accumulator[networkIdString] = {
            label: operator.name_mapped,
            color,
            backgroundColor: color,
            operator,
            data: [formattedDatum],
          };
        }
      }

      return accumulator;
    },
    {} as Record<string, DataSeries>,
  );

  for (const key in _trendSeries) {
    _trendSeries[key].data.sort((a, b) => a.date.localeCompare(b.date));
  }

  return _trendSeries;
});

const isLoading = computed(() => {
  return geocodingConfig.isLoading.value;
});

const noData = computed(() => {
  return !isLoading.value && (!trendSeries.value || Object.values(trendSeries.value).length === 0);
});

/**
 * A set of items to render in the screenshot as a legend. Should contain every operator
 * in the chart, and their value on the last day, I guess.
 */
const legendItems = computed(() => {
  if (!trendSeries.value) {
    return [];
  } else {
    return Object.values(trendSeries.value)
      .map((series) => {
        const operator = series.operator;
        const lastDatum = series.data[series.data.length - 1];

        return {
          ...operator,
          ...lastDatum,
          name_mapped: `${operator.name_mapped} (${operator.country_iso3})`, // hijack the name_mapped field to include the country code
        };
      })
      .sort((left, right) => {
        // from getDatumValue, up above, in the trendSeries computed prop
        return right.y - left.y;
      });
  }
});

const getOperatorISO3 = (series: DataSeries) => {
  return series.operator.country_iso3;
};

const matchedMetric = computed(() => {
  const _metric = unref(metric);
  const _metricsByIdentifier = unref(metricsByIdentifier);
  return _metricsByIdentifier[_metric] as MetricDescriptor;
});

const titleLabels = computed(() => {
  const _endDate = unref(endDate);
  const _nbDays = unref(nbDays);
  const _userGroup = unref(userGroup);
  const _matchedMetric = unref(matchedMetric);

  const startDateLabel = format(subDays(_endDate, _nbDays), API_LONG_DATE_FORMAT);
  const endDateLabel = format(endDate.value, API_LONG_DATE_FORMAT);
  const foundAggregation = AGGREGATIONS.find((_agg) => aggregation.value === _agg.value);
  const aggregationLabel = foundAggregation ? foundAggregation.label : aggregation.value;

  const userGroupObj = _userGroup !== undefined ? USER_GROUPS.find((group) => group.value === _userGroup) : undefined;
  const fullTitle =
    userGroupObj !== undefined
      ? `${METRIC_TYPE_NAMES[_matchedMetric.type as MetricTypeNamesIndex]} ${_matchedMetric.name} - ${
          userGroupObj.label
        }`
      : `${METRIC_TYPE_NAMES[_matchedMetric.type as MetricTypeNamesIndex]} ${_matchedMetric.name}`;
  const geography = 'Multi-Country';
  const lastUpdatedDaysAgo =
    dashboardInfo.data.value?.data.data_last_updated !== undefined
      ? differenceInDays(new Date(), getSafeDate(dashboardInfo.data.value?.data.data_last_updated) as Date)
      : 0;

  return {
    aggregation: aggregationLabel,
    chartTitle: {
      aggregation: aggregationLabel,
      baseTitle: _matchedMetric.name,
      endDate: endDateLabel,
      fullTitle,
      geography,
      lastUpdated: 0,
      metricName: _matchedMetric.name,
      metricType: _matchedMetric.type,
      metricUnit: _matchedMetric.units.short,
      startDate: startDateLabel,
    },
    endDate: endDateLabel,
    fullTitle,
    geography,
    name: _matchedMetric.name,
    product: 'competitive',
    startDate: startDateLabel,
    lastUpdated: lastUpdatedDaysAgo,
    units: _matchedMetric.units.short,
    warning: false,
  };
});

// for changing the end date via a callback in the trendchart
const { endDate: endDateQueryParam } = useSpotlightQueryParams();
const onClickLineChart = (pointDate: string) => {
  if (endDate.value.toISOString() !== pointDate) {
    endDateQueryParam.onChange(pointDate);
  }
};
</script>
<template>
  <ChartWrapperExtended
    :loading="isLoading"
    :empty="noData"
    title="Competitive Trends"
    :show-confidence-rate="true"
    :picture-meta="titleLabels"
    :chart-data="trendSeries"
    :title-labels="titleLabels"
    :selected-point="legendItems"
    :unit="matchedMetric.units.short"
  >
    <LineChart
      v-if="trendSeries"
      :data-set="Object.values(trendSeries)"
      :date="endDate.toISOString()"
      :title="titleLabels.fullTitle"
      :end-date="titleLabels.endDate"
      :show-confidence-rate="true"
      :show-line-icons="true"
      :get-tooltip-hint="getOperatorISO3"
      :height="350"
      :show-compare="false"
      :span-gaps="true"
      chart-id="trend"
      class="Details__chartView"
      :show-rank-gaps="true"
      @point="onClickLineChart"
    />
  </ChartWrapperExtended>
</template>
<style lang="scss"></style>
