<script setup lang="ts">
import { ref } from 'vue';

import IntlSpotlightBreadcrumbs from '@/intl-spotlight/IntlSpotlightBreadcrumbs.vue';
import OnxSpotlightHeaderNav from '@/components/onx/spotlight-header/OnxSpotlightHeaderNav.vue';
import HtmlImageExport from '@/components/HtmlImageExport.vue';
import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';
import OnxPaper from '@/components/onx/OnxPaper.vue';
import OnxList from '@/components/onx/OnxList/OnxList.vue';
import OnxListItem from '@/components/onx/OnxList/OnxListItem.vue';
import OnxListItemText from '@/components/onx/OnxList/OnxListItemText.vue';
import DownloadFileIcon from '@/components/onx/icons/DownloadFileIcon.vue';
import MenuDotsIcon from '@/components/onx/icons/MenuDotsIcon.vue';
import useSpotlightTilesImageExportTitle from '@/spotlight/useSpotlightTilesImageExportTitle';

const matches = useBreakpoints();
const { exportTitle } = useSpotlightTilesImageExportTitle('By Connection', true);

const htmlImageExportRef = ref();

const onHtmlImageExportListItemClick = () => {
  if (!htmlImageExportRef.value) {
    return;
  }

  htmlImageExportRef.value.print();
};

const getExportTarget = () => {
  return document.querySelector('[data-export-image-target]');
};
</script>

<template>
  <IntlSpotlightBreadcrumbs />
  <OnxSpotlightHeaderNav>
    <template #actions>
      <HtmlImageExport
        v-show="matches.desktop.value"
        ref="htmlImageExportRef"
        :get-element="getExportTarget"
        :title="exportTitle"
      />

      <VDropdown
        v-if="matches.mobile.value && !matches.desktop.value"
        :distance="6"
        class="onx-navigation-header__settings"
      >
        <span>
          <MenuDotsIcon button />
        </span>

        <template #popper>
          <OnxPaper :depth="3">
            <OnxList>
              <OnxListItem extra-x-padding @click.prevent="onHtmlImageExportListItemClick">
                <OnxListItemText size="sm"> Save as image </OnxListItemText>
                <template #right>
                  <DownloadFileIcon />
                </template>
              </OnxListItem>
            </OnxList>
          </OnxPaper>
        </template>
      </VDropdown>
    </template>
  </OnxSpotlightHeaderNav>
</template>
