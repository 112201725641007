import { computed, MaybeRef, unref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const useQueryParamToggle = (queryParam: string, defaultValue: MaybeRef<boolean>) => {
  const route = useRoute();
  const router = useRouter();

  const isActive = computed<boolean>(() => {
    const queryParamValue = route.query[queryParam];

    if (queryParamValue === undefined) {
      return unref(defaultValue);
    } else {
      return queryParamValue === 'true';
    }
  });

  const toggle = () => {
    const newValue = !isActive.value;
    const payload = {
      query: {
        ...route.query,
        [queryParam]: newValue.toString(),
      },
    };

    return router.push(payload);
  };

  const reset = () => {
    return router.replace({
      query: {
        ...route.query,
        [queryParam]: unref(defaultValue).toString(),
      },
    });
  };

  const toValue = () => {
    return isActive.value;
  };

  return {
    toggle,
    isActive,
    reset,
    toValue,
  };
};

export default useQueryParamToggle;
