import { congestionCommonEndColumns, congestionCommonStartColumns } from './congestionCommonColumns';
import { Column } from '@/components/onx/table';
import { ascNumberSort } from '@/utils/sorting';
import { CongestionMostCongestedCellsRow } from '@/chart-metric-definitions/data-transformers/prepareCongestionMostCongestedCellsForTable';

export const congestionMostCongestedCellsColumns: Column<CongestionMostCongestedCellsRow>[] = [
  ...congestionCommonStartColumns,
  {
    value: (row) => row.congestionScore,
    cell: (row) => (row.congestionScore != null ? Math.round(row.congestionScore) : ''),
    header: 'Congestion Score',
    key: 'congestion_score',
    sort: ascNumberSort,
  },
  {
    value: (row) => row.downlinkScore,
    cell: (row) => (row.downlinkScore != null ? Math.round(row.downlinkScore) : ''),
    header: 'Downlink Score',
    key: 'downlink_score',
    sort: ascNumberSort,
  },
  {
    value: (row) => row.uplinkScore,
    cell: (row) => (row.uplinkScore != null ? Math.round(row.uplinkScore) : ''),
    header: 'Uplink Score',
    key: 'uplink_score',
    sort: ascNumberSort,
  },
  {
    value: (row) => row.downloadUploadDelta,
    cell: (row) => (row.downloadUploadDelta != null ? Math.round(row.downloadUploadDelta) : ''),
    header: 'DL-UL Delta',
    key: 'dl_ul_delta',
    sort: ascNumberSort,
  },
  ...congestionCommonEndColumns,
];
