import useQueryParamSingleSelect from '@/composables/useQueryParamSingleSelect';
import useQueryParamToggle from '@/composables/useQueryParamToggle';

type QueryParams =
  | 'agg'
  | 'compareTo'
  | 'date'
  | 'geocoding'
  | 'location'
  | 'metric'
  | 'countryid'
  | 'network'
  | 'connectionCategory'
  | 'userGroup'
  | 'showScoreChange';

const useSpotlightQueryParams = (defaults?: { [key in QueryParams]?: string }) => {
  const agg = useQueryParamSingleSelect<string>('agg', defaults?.agg || '90days');
  const compareTo = useQueryParamSingleSelect<string>('compareTo', defaults?.compareTo || '90days');
  const endDate = useQueryParamSingleSelect<string>('endDate', defaults?.date || '');
  const geocoding = useQueryParamSingleSelect<string>('geocoding', defaults?.geocoding || '');
  const location = useQueryParamSingleSelect<string>('location', defaults?.location || '');
  const metric = useQueryParamSingleSelect<string>('metric', defaults?.metric || '');
  const countryid = useQueryParamSingleSelect<string>('countryid', defaults?.countryid || '');
  const network = useQueryParamSingleSelect<string>('network', defaults?.network || 'all');

  const connectionCategory = useQueryParamSingleSelect<string>(
    'connectionCategory',
    defaults?.connectionCategory || 'overall',
  );
  const userGroup = useQueryParamSingleSelect<string>('userGroup', defaults?.userGroup || 'main');

  const showScoreChange = useQueryParamToggle('showScoreChange', false);

  return {
    agg,
    compareTo,
    endDate,
    geocoding,
    location,
    metric,
    countryid,
    network,
    connectionCategory,
    userGroup,
    showScoreChange,
  };
};

export default useSpotlightQueryParams;
