<script setup lang="ts">
import { computed } from 'vue';
import { useRoute, RouterLink, useRouter } from 'vue-router';

import OnxHeaderTab from '@/components/onx/tabs/OnxHeaderTab.vue';
import OnxTabs from '@/components/onx/tabs/OnxTabs.vue';
import OnxTabsContext from '@/components/onx/tabs/OnxTabsContext.vue';
import useDefaultLocationId from '@/composables/useDefaultLocation';

import Routes from '@/constants/routes';
import { OS_GEOCODINGS } from '@/constants/constants';

const route = useRoute();
const router = useRouter();

const defaultLocationId = useDefaultLocationId();

const subRoute = computed(() => route.matched?.[1].path.split('/')[2]);

// Figure out IntlSpotlight to Spotlight route
// e.g /spotlight-multicountry/details to /spotlight/details
const routeLocationFromMulticountryToSingleCountry = computed(() => {
  const resolvedMirrorRoute = router.resolve({ name: Routes.Spotlight });
  return {
    path: `${resolvedMirrorRoute.path}/${subRoute.value}`,
    query: {
      ...route.query,
      location: route.query.location || defaultLocationId.value,
      countryid: route.query.countryid || defaultLocationId.value,
      network: route.query.network || 'all',
      geocoding:
        route.query.geocoding === OS_GEOCODINGS.countries.toString() ? OS_GEOCODINGS.regions : route.query.geocoding,
      agg: route.query.metric?.includes('coverage') ? '90days' : route.query.agg,
    },
  };
});

// Figure out Spotlight to IntlSpotlight route
// e.g /spotlight/details to /spotlight-multicountry/details
const routeLocationFromSingleCountryToMulticountry = computed(() => {
  const resolvedMirrorRoute = router.resolve({ name: Routes.IntlSpotlight });
  return {
    path: `${resolvedMirrorRoute.path}/${subRoute.value}`,
    query: route.query,
  };
});

const matchedTab = computed(() => route.matched?.[0].name?.toString());
</script>

<template>
  <OnxTabsContext :selected-tab="matchedTab">
    <OnxTabs class="onx-focus-header__main-tabs">
      <OnxHeaderTab
        :id="Routes.IntlSpotlight"
        :as="RouterLink"
        :to="routeLocationFromSingleCountryToMulticountry"
        class="neutral-anchor-link"
      >
        <span>Multi-Country</span>
      </OnxHeaderTab>
      <OnxHeaderTab
        :id="Routes.Spotlight"
        :as="RouterLink"
        :to="routeLocationFromMulticountryToSingleCountry"
        class="neutral-anchor-link"
      >
        <span>Single Country</span>
      </OnxHeaderTab>
    </OnxTabs>
  </OnxTabsContext>
</template>
