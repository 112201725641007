<template>
  <div class="empty">
    <div class="empty__icon">
      <FontAwesomeIcon :icon="icons.faChartBar" size="4x" />
    </div>
    <div>No data</div>
  </div>
</template>

<script>
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'NoData',
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      icons: {
        faChartBar,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.empty {
  padding: 0.5em;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__icon {
    opacity: 0.1;
  }
}
</style>
