<script setup lang="ts">
import { computed, withDefaults } from 'vue';
import LoadingIndicatorBase from '@/components/LoadingIndicatorBase.vue';

type Props = {
  size?: 'sm' | 'md' | 'lg';
};

const props = withDefaults(defineProps<Props>(), {
  size: 'md',
});

const size = computed(() => {
  switch (props.size) {
    case 'sm':
      return '24';
    case 'lg':
      return '96';
    case 'md':
    default:
      return '48';
  }
});
</script>

<template>
  <LoadingIndicatorBase>
    <svg class="loader-grid__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" :width="size" :height="size">
      <g class="nc-loop-squares-48-icon-f">
        <rect height="21" width="21" x="24" y="3" />
        <rect height="21" width="21" x="24" y="24" />
        <rect height="21" width="21" x="3" y="3" />
        <rect height="21" width="21" x="3" y="24" />
      </g>
    </svg>
  </LoadingIndicatorBase>
</template>

<style>
.nc-loop-squares-48-icon-f {
  fill: var(--dark-teal-500);
}

.nc-loop-squares-48-icon-f > * {
  --animation-duration: 1.3s;
  transform-origin: 50% 50%;
  animation: nc-loop-squares-anim var(--animation-duration) infinite;
}

.nc-loop-squares-48-icon-f > :nth-child(2) {
  animation-delay: 0.1s;
}

.nc-loop-squares-48-icon-f > :nth-child(3) {
  animation-delay: 0.2s;
}

.nc-loop-squares-48-icon-f > :nth-child(4) {
  animation-delay: 0.3s;
}

@keyframes nc-loop-squares-anim {
  0%,
  100%,
  80% {
    opacity: 1;
    transform: scale(1);
  }

  50% {
    opacity: 0;
    transform: scale(0);
  }
}
</style>
