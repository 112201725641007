<script setup lang="ts">
import { ref, computed, watch, nextTick, onMounted } from 'vue';
import mapboxgl from 'mapbox-gl';

import type { Feature, FeatureCollection } from 'geojson';

import { MapEvents } from '@/components/visual/map/MapEvents';
import { geoJsonMeta } from '@/components/visual/map/helpers';

import useBreakpoints from '@/components/onx/composables/responsive/useBreakpoints';
import naiveId from '@/utils/naiveId';
import { LOCAL_STORAGE_KEYS } from '@/constants/constants';

const MapId = naiveId();

type Props = {
  geoJson: FeatureCollection;
  // not required for Intl Spotlight
  locationId?: number | null;
  // not required for Intl Spotlight
  selectedFeature?: Feature;
  maxZoom?: number;
  colourScaleVisible?: boolean;
  colourScaleLabels?: string[];
  colourScaleInverted?: boolean;
  boundsModifiers?: {
    width: number;
    height: number;
  };
  noWrap?: boolean;
  zoomSnap?: number;
};

const props = withDefaults(defineProps<Props>(), {
  maxZoom: 19,
  geoJson: () => ({ type: 'FeatureCollection', features: [] }),
  colourScaleVisible: false,
  colourScaleLabels: () => [],
  colourScaleInverted: false,
  boundsModifiers: () => ({
    width: 0.01,
    height: 0.01,
  }),
  noWrap: false,
  locationId: null,
  zoomSnap: 0,
});

const emit = defineEmits({
  /* eslint-disable @typescript-eslint/no-unused-vars */
  [MapEvents.MapReady]: (map: mapboxgl.Map) => true,
  [MapEvents.NewBounds]: (bounds: { size: { x: number; y: number }; bounds: mapboxgl.LngLatBounds }) => true,
  /* eslint-enable @typescript-eslint/no-unused-vars */
});

const haveBoundsChanged = (oldBounds: mapboxgl.LngLatBounds | null, newBounds: mapboxgl.LngLatBounds) => {
  if (!oldBounds) {
    return true;
  }

  const threshold = 0.05;

  const roundedNewBounds = {
    south: Math.round(newBounds.getSouth()),
    west: Math.round(newBounds.getWest()),
    north: Math.round(newBounds.getNorth()),
    east: Math.round(newBounds.getEast()),
  };

  return (
    Math.abs(oldBounds.getSouth() - roundedNewBounds.south) > threshold ||
    Math.abs(oldBounds.getWest() - roundedNewBounds.west) > threshold ||
    Math.abs(oldBounds.getNorth() - roundedNewBounds.north) > threshold ||
    Math.abs(oldBounds.getEast() - roundedNewBounds.east) > threshold
  );
};

const matches = useBreakpoints();

const map = ref<mapboxgl.Map>();
const bounds = ref<mapboxgl.LngLatBounds | null>(null);

const chartMeta = computed(() => {
  return geoJsonMeta(props.geoJson, props.boundsModifiers.height, props.boundsModifiers.width);
});

const selectedMeta = computed(() => {
  if (!props.selectedFeature) {
    return null;
  }

  return geoJsonMeta(props.selectedFeature, props.boundsModifiers.height, props.boundsModifiers.width);
});

const baseMapBounds = computed(() => {
  // sw, ne
  return props.selectedFeature
    ? selectedMeta.value!.maxBounds
    : (chartMeta.value.maxBounds as [[number, number], [number, number]]);
});

const fitMapBounds = () => {
  if (!map.value) {
    return;
  }

  map.value.fitBounds(
    [
      // west, south, east, north
      baseMapBounds.value[0][1],
      baseMapBounds.value[0][0],
      baseMapBounds.value[1][1],
      baseMapBounds.value[1][0],
    ],
    { animate: false },
  );
};

const onLoad = async () => {
  await nextTick();
  fitMapBounds();
};

const onMoveEnd = () => {
  if (!map.value) {
    return;
  }

  const newBounds = map.value.getBounds();

  if (newBounds === null) {
    return;
  }

  if (!haveBoundsChanged(bounds.value, newBounds)) {
    return;
  }

  bounds.value = newBounds;

  const mapContainer = document.getElementById(MapId);
  emit(MapEvents.NewBounds, {
    size: { x: mapContainer?.clientWidth ?? 0, y: mapContainer?.clientHeight ?? 0 },
    bounds: newBounds,
  });
};

// Register this watcher if a locationId is provided
if (props.locationId === undefined) {
  watch(
    () => props.locationId,
    (oldLocationId, newLocationId) => {
      if (!oldLocationId || !newLocationId) {
        fitMapBounds();
      }
    },
  );
}

// Register this watcher if a selectedFeature is provided
if (props.selectedFeature === undefined) {
  watch(
    () => props.selectedFeature,
    (newSelected, oldSelected) => {
      if (!oldSelected || !newSelected || newSelected.id !== oldSelected.id) {
        fitMapBounds();
      }
    },
  );
}

onMounted(async () => {
  map.value = new mapboxgl.Map({
    container: MapId,
    dragPan: matches.laptop.value,
    doubleClickZoom: false,
    maxZoom: props.maxZoom,
    style: 'mapbox://styles/mapbox/light-v11',
    projection: { name: 'mercator' },
    transformRequest: (url, resourceType) => {
      if (resourceType === 'Tile' && url.startsWith(import.meta.env.VITE_BASE_URL)) {
        const token = window.localStorage.getItem(LOCAL_STORAGE_KEYS.OS_TOKEN);
        return {
          url,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      }

      return {
        url,
      };
    },
  });

  map.value.dragRotate.disable();
  map.value.touchZoomRotate.disableRotation();

  if (!map.value) {
    return;
  }

  map.value.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'bottom-right');

  map.value.on('moveend', onMoveEnd);
  map.value.once('load', () => {
    if (!map.value) {
      return;
    }

    map.value.addSource('empty', {
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
    });
    map.value.addLayer({
      id: 'z-index-1',
      type: 'symbol',
      source: 'empty',
    });

    emit(MapEvents.MapReady, map.value);

    const bounds = map.value.getBounds();

    if (bounds === null) {
      return;
    }

    const mapContainer = document.getElementById(MapId);
    emit(MapEvents.NewBounds, {
      size: { x: mapContainer?.clientWidth ?? 0, y: mapContainer?.clientHeight ?? 0 },
      bounds,
    });
  });

  await onLoad();
});
</script>

<template>
  <div class="map-size">
    <div v-if="geoJson" :id="MapId" class="map-com ci-map map-size"></div>
    <slot></slot>
  </div>
</template>

<style lang="scss">
@use 'scss/variables.module' as *;
@import '@material/elevation';
@import 'scss/onx-breakpoints.module';

.map-size {
  height: 100%;
  width: 100%;
  position: relative;
}
</style>
