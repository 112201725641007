import { differenceInDays, format, subDays } from 'date-fns';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import { CDNS, AGGREGATIONS, USER_GROUPS } from '@/constants/constants';
import { API_LONG_DATE_FORMAT } from '@/constants/dateFormats';
import { getSafeDate } from '@/utils/date';
import { getUnit } from '@/utils/metrics';

const deviceLabel = (metric = {}) => {
  // eslint-disable-next-line prefer-const
  let [base, modifier] = metric.name ? metric.name.split(' by ') : ['', ''];
  base = base.replace('4G ', '');

  return {
    base,
    modifier,
  };
};

/** Converts '5g' to '5G', 'LTE' to '4G', etc. */
const getConnectionCategoryTitle = (category) => {
  switch (category.toUpperCase()) {
    case 'LTE':
      return '4G';
    case 'LTE5G':
      return '4G/5G';
    case '5GMMWAVE':
      return 'mmWave';
    case '5GLOW':
      return '5G Low Frequency Range';
    case '5GMID':
      return '5G Mid Frequency Range';
    case '5GHIGH':
      return '5G High Frequency Range';
    case '3GLTE5G':
    case 'OVERALL':
      return 'Overall';
    case 'NOSIGNAL':
      return 'No Signal';
    case 'EMERGENCY':
      return 'Emergency';
    default:
      return category.toUpperCase();
  }
};

/*
  e.g.: ['Download Speed LTE', 'Latency LTE by CDN', 'Peak Latency 3G' ...]
  format: `${baseTitle} ${category} ${type} ${cdn}`
 */
const metricToFullTitle = (metric, { includeAllUsersGroup = false, includeTypeLabel = true } = {}) => {
  if (metric.key == 'availability_wifi') {
    // we want to preserve the name as "Time on WIFI"
    return metric.name;
  }
  const typeLabel = includeTypeLabel && metric.type ? getConnectionCategoryTitle(metric.type) : '';

  const baseTitle = metric.name;
  const betaLabel = metric.beta ? ' BETA' : '';
  const userGroupName =
    includeAllUsersGroup && !metric.user_group
      ? 'All Users'
      : USER_GROUPS.find((userGroup) => userGroup.value == metric.user_group)?.label ?? '';
  const userGroupLabel = userGroupName ? `- ${userGroupName}` : '';

  return `${typeLabel} ${baseTitle} ${userGroupLabel} ${betaLabel}`.replace(/\s\s+/g, ' ');
};

const trendsByCdnTitle = (metric, cdn) => {
  const fullTitle = metricToFullTitle(metric);
  return `${fullTitle} Trends for ${CDNS[cdn] || cdn}`;
};
/*
  'video', 'download', 'latency', 'download binned'
 */
const getMetricNameLabel = (kind, subcategory, structure) => {
  if (kind === 'video') return kind;
  if (subcategory === 'main') return kind;
  if (structure === 'breakdown') return kind;

  return kind && subcategory && `${kind} ${subcategory}`;
};

/*
   All [Country]: Download Speed LTE/3G Overview
   All [Country]: Download Peak Speed LTE/3G Overview
   All [Country]: Download Speed LTE/3G by CDN Overview
   All [Country]: Upload Speed LTE/3G Overview
   All [Country]: Upload Peak Speed LTE/3G Overview
   All [Country]: Upload Speed LTE/3G by CDN Overview
   All [Country]: Latency LTE/3G Overview
   All [Country]: Minimum Latency LTE/3G Overview
   All [Country]: Latency LTE/3G by CDN Overview
 */

const titleLabels = (currentLocation, metric, date, agg, dashboardInfo = {}) => {
  if (!date) {
    return;
  }
  const aggregation = AGGREGATIONS.find((a) => a.value === agg);
  const startDate = aggregation && aggregation.days && subDays(getSafeDate(date), aggregation.days);
  return getCustomTitleLabels(currentLocation, metric, startDate, date, agg, dashboardInfo);
};

const getCustomTitleLabels = (
  currentLocation,
  metric,
  startDate = false,
  endDate,
  agg,
  dashboardInfo = {},
  includeAllUsersGroupLabel = false,
) => {
  const DATE_FORMAT = API_LONG_DATE_FORMAT;
  const aggregation = AGGREGATIONS.find((a) => a.value === agg);
  const metricName = getMetricNameLabel(get(metric, 'kind'), get(metric, 'subcategory'), get(metric, 'structure'));
  const metricType = get(metric, 'type');
  const lastUpdated = dashboardInfo.data_last_updated;

  const finalStartDate = startDate ? format(getSafeDate(startDate), DATE_FORMAT) : null;
  const finalEndDate = format(getSafeDate(endDate), DATE_FORMAT);

  return {
    geography: geographyLabel(currentLocation),
    metricName: metricName && startCase(metricName),
    metricType: metricType && metricType.toUpperCase(),
    metricUnit: getUnit(metric),
    startDate: finalStartDate,
    endDate: finalEndDate,
    aggregation: aggregation && aggregation.full,
    lastUpdated: lastUpdated && differenceInDays(new Date(), getSafeDate(lastUpdated)),
    baseTitle: metric.name,
    fullTitle: metricToFullTitle(metric, { includeAllUsersGroup: includeAllUsersGroupLabel }),
    byCdn: get(metric, 'subcategory') === 'cdn' ? 'by CDN' : undefined,
  };
};

function geographyLabel(location) {
  if (!location) return '';

  return location.granularity === 'opensignal_countries'
    ? `All ${location.name}`
    : `${location.iso3},  ${location.name}`;
}

const chartExtendedTitles = (currentLocation, metric, extension) => {
  const { fullTitle, geography } = titleLabels(currentLocation, metric);
  const unit = getUnit(metric);
  const showedUnit = unit ? `in <i>${unit}</i>` : '';
  return metric && `${geography}: ${fullTitle} ${showedUnit} | ${extension}`;
};

const chartTitleWithUnits = (metric) => {
  const unit = getUnit(metric);
  const fullTitle = metricToFullTitle(metric);
  return unit ? `${fullTitle} (${unit})` : fullTitle;
};

export {
  chartExtendedTitles,
  chartTitleWithUnits,
  deviceLabel,
  getConnectionCategoryTitle,
  getCustomTitleLabels,
  getMetricNameLabel,
  metricToFullTitle,
  titleLabels,
  trendsByCdnTitle,
};
